export const SEND_EVENT_TYPE = "WEB_CHAT/SEND_EVENT";

export const getWebChatJoin = (user, tempCurrentWeek, currentAttempt = undefined) => {
    return {
    type: SEND_EVENT_TYPE,
    payload: {
      name: "webchat/join",
      value: {
        language: window.navigator.language,
        name: user.name,
        week: Number(tempCurrentWeek),
        currentAttempt: currentAttempt ?? user.psybot_attempt
      },
    },
  }
}