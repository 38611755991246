import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderFunction from "./common/HeaderFunctions";
import { useTranslation } from 'react-i18next';
import routes from "../routes";
import DonutChart from "./charts/DonutChart";
import { useSelector } from "react-redux";
import api from "../api";
import LineChart from "./charts/LineChart";
import moment from "moment";
import "./style.css";

import mtz from 'moment-timezone';

const HomeScreen = ({ setPath, path, progress }) => {
  const { user } = useSelector((state) => state.userReducer);

  const [data, setData] = React.useState([
    { value: 0 },
    { value: 100 }
  ]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [severity, setSeverity] = useState();
  const [stat, setStat] = useState();
  const [assesment, setAssesment] = useState();
  const [chartData, setChartData] = useState();
  const [appointment, setAppointment] = useState();
  const [activeScore, setActiveScore] = useState();
  const [collapse, setCollapse] = useState(false);
  const handleGoToRoute = () => {
    navigate('/');
  };

  const getAssesmentScorePercent = (assesment, stat) => {
    const type = getAssesmentType(assesment);
    let score = 0;
    if (type === "PHQ-9") {
      score = (parseInt(stat.score) / 27) * 100;
    } else if (type === "GAD-7") {
      score = (parseInt(stat.score) / 21) * 100;
    }
    if (score > 0) {
      setData([
        { value: score },
        { value: 100 - score }
      ])
    } else {
      setData([
        { value: 0 },
        { value: 100 }
      ])
    }
    return score.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
  }

  const getAssesmentType = (assesment) => {
    if (assesment.question_id === "1") {
      if (assesment.answer_id === "1") {
        return "PHQ-9";
      } else if (assesment.answer_id === "2") {
        return "GAD-7";
      }
    }

    if (assesment.question_id === "43") {
      if (assesment.answer_id === "153") {
        return "PHQ-9";
      } else if (assesment.answer_id === "154") {
        return "GAD-7";
      }
    }

  }

  const showBooking = useMemo(() => {
    return  Number(chartData?.y?.[chartData.y.length - 1]) > 9;
  }, [chartData]);
  
  useEffect(() => {
    (async () => {
      const res = await api.fetchUserAssesmentStats({
        user_device_token: user.device_token,
        timeZone:  mtz.tz.guess()
      });
      const json = (await res.json());
      const stat = JSON.parse(JSON.stringify(json.stat));
      setStat(json.assesment);
      setAssesment(json.firstAnswer);
      getAssesmentScorePercent(json.firstAnswer, json.stat[json.stat.length - 1]);
      setSeverity(json.stat[json.stat.length - 1].severity)

      setAppointment(json.appointment);
      const y = stat.map((temp) => Number(temp.score));
      const x = stat.map((temp) => temp?.created || temp?.mh_datetime);
      setChartData({ x, y })
    })();
  }, [])
  console.log({a: appointment})
  return (
    <div className="home">
      <div className="adjust-to-middle" style={{height:'100vh'}}>
        <div className="top-header home-screen-header">
          <div className="page-header-container">
            <div>
              <span className="home-logo-img" >
              </span>
            </div>
            <HeaderFunction />
          </div>
        </div>
        <div className="top-header mt-2 row" style={{
          display: 'flex',
          paddingBottom: '35px'
        }}>
          <div className="col-4">
            <div className="circular-progress">
              <div>
                <DonutChart data={data} />
              </div>
            </div>
          </div>
          <div className="title-container col-8">
            <p className="title">{severity ? t(String(severity).toLowerCase()) : ""} {stat?.issue ? t(String(stat?.issue).toLowerCase()): ""}</p>
          </div>
        </div>

        <div className="top-header mt-2" style={{
          minHeight: '60px',
          paddingTop: '0'
        }} >
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '60px',
            padding: '20px',
            width: '97%'
          }}>
            <span className="statName">
              {stat?.score && assesment && getAssesmentType(assesment)}
            </span>
            <span className="statScore">
              {chartData?.y?.[chartData.y.length - 1]}
              {chartData?.x?.length > 1 && <i onClick={() => setCollapse(!collapse)} className={`statIcon fa ${!collapse ? 'fa-plus' : 'fa-minus'}`}></i>}
            </span>
          </div>
          <div className="card" style={{
            minHeight: '200px',
            padding: '20px',
            width: '92%',
            marginLeft: '16px',
            display: !collapse ? 'none' : 'block'
          }}>
            <div>
              <p className="chartDate">{activeScore?.date && moment(activeScore?.date).format('Do MMMM, YYYY')}</p>
            </div>
            <div>
              <p className="title chartScore">{activeScore?.score}</p>
            </div>
            <div style={{
              height: '200px'
            }}>
              {chartData?.x && <LineChart onPointClickHandler={(index) => {
                const score = chartData.y[index];
                const date = chartData.x[index];
                setActiveScore({
                  score,
                  date
                })
              }} chartData={chartData} />}
            </div>
          </div>
        </div>
        {Number(chartData?.y?.[chartData.y.length - 1]) > 9 && !collapse && (<div className="banner-section position-relative w-100" style={{height: '170px', width: '97%', marginTop: '5rem', marginBottom: '5rem'}}>
        <div class="ribbon"><span>{t('Recommended')}</span></div>
        <div className="col-sm-6 order-sm-1 order-2">
          {!appointment && <p className="fs-3 fw-bold mb-0">{t('book_now_h1')}</p>}
          {!appointment && <p className="fs-6 fw-bold mb-0">{t('book_now_h2')}</p>}
          {appointment && <p className="fs-3 fw-bold mb-0">{t('booked_h1')}</p>}
          <small className="banner-small-text">
            {t('book_now_t')}
          </small>
          <div className="mt-3 col-6">
          </div>
        </div>
        <div className="col-sm-6 offset-lg-9 offset-sm-3 order-1 order-sm-2 m-4">
            <button
              type="button"
              className="btn btn-purple p-2"
              onClick={() => navigate(!appointment ? routes.DOCTORS_LIST : routes.APPOINTMENT)}
            >
              {t(!appointment ? 'book_now_btn' : 'booked_btn')}
            </button>
        </div>
      </div>)}
        <div className="w-100" style={{
          position: !showBooking || collapse ? 'fixed' : 'static',
          bottom: !showBooking || collapse ? '36px' : '',
        }}>
        <div className="d-flex justify-content-evenly align-items-center home-sub-container">
          <button className="border-0 bg-transparent" onClick={handleGoToRoute}>
            <div className="home-menu-container purple-menu-bg">
              <div className="menu-name">
                <i className="fa-regular fa-message"></i>
                <span className="menu-text">{t('chat_menu_title')}</span>
              </div>
              <div className="submenu-name">
                {t('chat_menu_subtitle')}
              </div>
            </div>
          </button>
          <button className="border-0 bg-transparent" onClick={() => {
            navigate("/toolbox")
          }}>
            <div className="home-menu-container pink-menu-bg">
              <div className="menu-name">
                <i className="fa-solid fa-toolbox text-white"></i>
                <span className="menu-text">{t('toolbox_menu_title')}</span>
              </div>
              <div className="submenu-name">
                {t('toolbox_menu_subtitle')}
              </div>
            </div>
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
