import React from "react";
import Modal from "../../../Modal";
import { useTranslation } from "react-i18next";

const CompletedThoughtRestructuring = ({
  modalStatusCompleted_,
  selectedItem,
  closeModal,
  setSelectedItem,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={modalStatusCompleted_ && Boolean(selectedItem)}>
      <div className="modal-header-wrapper">
        <div className="d-flex justify-content-between bg-white align-items-center modal-header">
          <span>{t("thought_restructure_title")}</span>
          <button
            type="button"
            className="close modal-close-btn-updated"
            onClick={() => {
              closeModal("modalStatusCompleted_");
              setSelectedItem(undefined);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div className="modal-body">
        <div className="d-flex justify-content-center lh-45">
          <div className="modal-scrn-5-top-heading bg-white bottom-left-24-rd">
            <div>
              <span className="">{t("negative_label")}<span className="text-danger">*</span></span>
            </div>
            <div className="overflow-wrap model-scrn-5-inner-content srcn-5-b-l-cont">
              {selectedItem?.negative_thought || ""}
            </div>
          </div>
          <div className="modal-scrn-5-top-heading br-left bg-white bottom-right-24-rd">
            <div>
              <span className="">{t("neutral_label")}<span className="text-danger">*</span></span>
            </div>
            <div className="overflow-wrap model-scrn-5-inner-content">
              {selectedItem?.neutral_thought || ""}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompletedThoughtRestructuring;
