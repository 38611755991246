import React, { useState, useEffect } from "react";
import SignupFooter from "./SignupFooter";
import SignupHeader from "./SignupHeader";
import SignUpPassword from "./SignUpPassword";
import SignUpEmail from "./SignUpEmail";
import { useFormik } from "formik";
import * as yup from 'yup';
import SignUpOTP from "./SignUpOTP";
import { firebase, auth } from "../../firebase";

const initialValues = {
  email: "",
  otp: ""
}

const schema = yup.object({
  email: yup.string().email().required(),
  otp: yup.string().required()
})

const SignUpWithEmail = () => {
  const onSubmitHandler = (values) => {

  }
  const { values, handleChange, errors, handleSubmit } = useFormik({
    initialValues,
    onSubmit: onSubmitHandler,
    validationSchema: schema
  }) 
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep((prevStep) => {
      const updatedStep = prevStep + 1;
      return updatedStep;
    });
  };

  const prevStep = () => {
    setStep((prevStep) => {
      const updatedStep = prevStep - 1;
      return updatedStep;
    });
  };

  const handleOtpSubmit = () => {
    nextStep();
  }

  const onPhoneNumberSetHandler = () => {
    const mynumber = values.email
    if (mynumber === "" || mynumber.length < 10) return;
    var d = document.getElementById("recaptcha-container");
    console.log(d);
    const verify = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log("response", response);
      },
    });

    auth
      .signInWithPhoneNumber("+91" + mynumber, verify)
      .then((result) => {
        this.setState({ otpSubmitted: true, loader: false, final: result });
      })
      .catch((err) => {
        console.log("Err", err);
        this.setState({
          loader: false,
          errors: {
            ...this.state.errors,
            errMessage: "Something Went Wrong. Try again later.",
          },
        });
      });
  };


  return (
    <div className="">
      <SignupHeader />
      <div className="form-group bottom-form">
        {step === 1 && (
          <SignUpEmail
            errors={errors}
            formData={values}
            handleChange={handleChange}
            nextStep={nextStep}
          />
        )}
        {step === 2 && (
          <SignUpOTP
            formData={values}
            errors={errors}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )}
        <button
        type="submit"
        className="btn  btn-green h-3 fw-200"
        onClick={(e) => {
            handleSubmit(e);
            if (step === 1 && !errors.email && Boolean(values.email)) {
              handleOtpSubmit();
            }
          }}
      >
         {step === 1 ? "Send One Time Password" : "Sign Up"}
      </button>
        
        <SignupFooter/>
      </div>
    </div>
  );
};

export default SignUpWithEmail;
