import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import routes from "../../routes";

const SignupFooter = ({}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="row text-center align-items-center mt-3 mx-1 h-3 hr-parent-container">
        <span className="col-md-4 hr-effect"></span>
        <span className="col-md-4 text-light-shade">or login with</span>
        <span className="col-md-4 hr-effect hr-right"></span>
      </div>
      {/* {location.pathname === "/signup" && (
        <button
          type="button"
          className="btn  btn-white mt-3 h-3"
          onClick={handleSignupWithEmail}
        >
          <i className="fa-solid fa-envelope px-3 color-green"></i> Continue
          with Email
        </button>
      )}
      {location.pathname === "/signup-email" && (
        <button
          type="button"
          className="btn  btn-white mt-3 h-3"
        >
          <i className="fa-solid fa-envelope px-3 color-green"></i> Continue with OTP
        </button>
      )}
      <button type="button" className="btn  btn-white mt-3 h-3">
        <i className="fa-brands fa-google px-3"></i> Continue with Google
      </button>
      */}
      <div className="d-flex justify-content-center align-items-center w-100 lh-5">
        <small className="fw-200">Already have an account?</small>
        <small onClick={() => {
          navigate(routes.SIGNUP_NAME)
        }} className="color-green fw-300 px-2">Login</small>
      </div> 
    </>
  );
};

export default SignupFooter;
