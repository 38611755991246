import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function LineChart({chartData, onPointClickHandler}) {
    const {x, y} = chartData;
    const data = {
        labels: x,
        datasets: [
            {
                fill: true,
                label: 'Score ',
                data: y,
                borderColor: '#92d0d1',
                backgroundColor: '#e9f9fd',
            },
        ],
    };
    return <Line options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
        },
        onClick: (event, element, chart) => {
            if(element.length > 0) {
                const elem = element[0];
                console.log(elem)
                if(String(elem.datasetIndex) != "") {
                    onPointClickHandler(elem.index);
                }
            }
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },

        elements: {
            line: {
                tension: 0.5,
            },
            point: {
                radius: 7,
            }
        },
    }} data={data} />;
}
