import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneNumberMaskedInput.css";


export const PhoneNumberMaskedInput = ({ value, onChange, isPhoneValid }) => {
  return (
    <>
      <PhoneInput
        inputStyle={{
          width: "100%",
          height: "52px",
          marginTop: "23px !important",
        }}
        country={"in"}
        onlyCountries={["in"]}
        countryCodeEditable={false}
        value={value}
        onChange={(phone) => onChange(phone)}
        enableSearch={true}
        isValid={Boolean(isPhoneValid)}
      />
    </>
  );
};
