import React, { useEffect, useState } from "react";
import ChatHeader from "../../ChatHeader";
import Modal from "../../../Modal";
import AddNew from "./AddNew";
import modals from "../../../../modals";
import { useNavigate } from "react-router-dom";
import ToolListHeader from "../../../common/tool-list/ToolListHeader";
import toolAction from "../../../../constant/tool-action";
import api from "../../../../api";
import { useSelector } from "react-redux";
import moment from "moment";
import ToolPreviewModal from "../../../common/tool-preview-modal/ToolPreviewModal";
import { useTranslation } from "react-i18next";

const ListCognitiveDistortion = ({ isOpen }) => {
  const { user } = useSelector((state) => state?.userReducer);
  const [list, setList] = React.useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const {t} =useTranslation();

  const openModal = (modalId) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [modalId]: true,
    }));
  };

  const closeModal = (modalId) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [modalId]: false,
    }));
  };

  const FetchTool = async () => {
    const response = await api.fetchCognitiveList({
      user_device_token: user?.user_device_token ?? user?.device_token,
    });
    setList((await response.json()).data);
  }
  useEffect(() => {
    FetchTool();
  }, []);

  const [mode, setMode] = React.useState(toolAction.PREVIEW);

  const [selectedItem, setSelectedItem] = React.useState();

  const cognitiveDistortionRef = React.useRef();

  return (
    <>
      <ChatHeader />
      <div className="modal-header-wrapper">
        <ToolListHeader
          buttonClickHandler={() => {
            openModal(modals.MODAL_STATUS_ADD_NEW_COG);
            setMode(toolAction.CREATE);
          }}
          buttonText={t("add_entry")}
          title={t("cog_title")}
        />
        <hr className="hr-toolbox" />
        {list.map((item, index) => (
          <div className="m-center w-80 mt-2">
            <button
              className="d-flex justify-content-between align-items-center toolbox-list-element toolbox-border-light w-100 bg-transparent"
              onClick={() => {
                if (item.status !== "COMPLETED") {
                  setMode(toolAction.EDIT);
                } else {
                  setMode(toolAction.PREVIEW);
                }
                openModal(modals.MODAL_STATUS_ADD_NEW_COG);
                setSelectedItem(item);
              }}
            >
              <div className="list-element ">{index + 1}</div>
              <div className="align-items-start d-flex flex-column justify-content-center">
                <span className="inner-top-element">
                {item.status === "COMPLETED" ? t("completed_lo") : t("partially")}
                   {" "}{t("attempts")}
                </span>
                <span className="list-caption">
                  {t("date")} : {moment(item.createdDate).format("Do, MMM YYYY")}
                </span>
              </div>
              <div>
                <i className="fa-solid fa-angle-right toolbox-right-icon"></i>
              </div>
            </button>
          </div>
        ))}

        <ToolPreviewModal
          isOpen={modalOpen?.[modals.MODAL_STATUS_ADD_NEW_COG]}
          onCloseClickHandler={() => {
            if (mode !== toolAction.PREVIEW) {
              openModal(modals.MODAL_SAVE_DISCARD);
            } else {
              closeModal(modals.MODAL_STATUS_ADD_NEW_COG);
            }
          }}
        >
          <AddNew
            selectedItem={selectedItem}
            closeModal={closeModal}
            mode={mode}
            ref={cognitiveDistortionRef}
            FetchTool={FetchTool}
          />
        </ToolPreviewModal>

        <Modal isOpen={modalOpen?.[modals.MODAL_SAVE_DISCARD]}>
          <div className="modal-content modal-backdrop">
            <div className="modal-body">
              <div className="">
                <div className="modal-discard-save p--7">
                  <div className="d-flex justify-content-center align-items-center fs-15">
                   {t("save_for_later_asking")}
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      id="left-togl-btn"
                      type="button"
                      className="btn btn-purple-light  mt-3 h-3 w-100"
                      onClick={() => {closeModal(modals.MODAL_SAVE_DISCARD);
                      closeModal(modals.MODAL_STATUS_ADD_NEW_COG);}}
                    >
                      {t("discard")}
                    </button>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className="btn btn-purple-light mt-3 h-3 w-100"
                      onClick={async () => {
                        await cognitiveDistortionRef.current.saveForLater();
                        closeModal(modals.MODAL_SAVE_DISCARD);
                      }}
                    >
                      {t("save_for_later")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ListCognitiveDistortion;
