import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactWebChat, { createDirectLine } from "botframework-webchat";
import { useSelector } from "react-redux";
import "../../../styles/WebChat.css";
import { css } from "@emotion/css";
// import ScrollToBottom from 'react-scroll-to-bottom';

const WebChat = (
  className,
  onFetchToken,
  store,
  // chatHistory,
  adtoken,
  userid,
  username,
) => {
  const outerWebChat = useRef();
  const { token, conversationId } = useSelector((state) => state?.chatReducer);
  const directLine = useMemo(
    () =>
      createDirectLine({
        token,
      }),
    [token]
  );

  const styleOptions = useMemo(
    () => ({
      botAvatarInitials: "BA",
      userAvatarInitials: "UA",
    }),
    []
  );

  function chatContainerHeight() {
    // reset the body height to that of the inner browser
    document.body.style.height = window.innerHeight + "px";
    document.body.style.overflow = "hidden";
    return `${window.innerHeight - 141}px`;
  }

  const [height, setHeight] = useState(chatContainerHeight());

  
  useEffect(() => {
    const resetHeight = () => {
      let originalHeight = window.innerHeight;
      if (window.innerHeight < originalHeight) {
        document.body.style.height = originalHeight + "px";
      } else {
        document.body.style.height = "auto";
      }
      if (outerWebChat?.current?.height) {
        outerWebChat.current.height = chatContainerHeight();
      }
    };
    // Update on window resize
    window.addEventListener("resize", resetHeight);
    return () => {
      window.removeEventListener("resize", resetHeight);
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);

  const ROOT_CSS = css({
    height: "100%",
    width: "100%",
  });

  const scrollableRef = useRef(null);

  const scrollToBottom = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  };

  return (
    <div className="h-100vh">
      {token ? (
        <div
          ref={outerWebChat}
          style={{
            height: height,
            overflow: "hidden",
          }}
        >
          {/* <ScrollToBottom className={ROOT_CSS} ref={scrollableRef}> */}
            <ReactWebChat
              className={`${className || ""} web-chat scrollToBottom`}
              directLine={directLine}
              store={store}
              userID={"user_" + userid}
              username={username}
              channelData={adtoken}
              locale={localStorage.getItem("lang") === "en" ? "en-us" : "hi-in"}
              styleOptions={styleOptions} // Use styleOptions for avatar customization
            />
          {/* </ScrollToBottom> */}
        </div>
      ) : (
        <div className={`${className || ""} connect-spinner`}>
          <div className="content">
            <div className="icon">
              <span className="ms-Icon ms-Icon--Robot" />
            </div>
            <p>Please wait while we are connecting.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebChat;
