import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    directLine: null,
    token: null,
    conversationId: null,
    tempStore: null,
    weekAsesment: []
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setResetChat(state, action) {
      state.directLine = null;
      state.token = null;
      state.conversationId = null;
      state.tempStore = null;
      state.weekAsesment = [];
    },
    setDirectLine(state, action) {
        state.directLine = action.payload;
    },
    setToken(state, action) {
        state.token = action.payload.token;
        state.conversationId = action.payload.conversationId;
    },
    setTempStore(state, action) {
      state.tempStore = action.payload;
    },
    setWeekAssesment(state, action) {
      state.weekAsesment = action.payload
    }
  },
});

export const { setDirectLine, setToken, setTempStore, setWeekAssesment, setResetChat } = chatSlice.actions;

export default chatSlice.reducer;
