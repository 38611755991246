import React, {useState} from "react";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../../api";
import { useSelector } from "react-redux";
import { pushToast } from "../../../../redux/slices/toastSlice";
import { useDispatch } from "react-redux";
import modals from "../../../../modals";
import toolAction from "../../../../constant/tool-action";
import TextArea from "../../../common/textarea/TextArea";
import { useTranslation } from "react-i18next";
import { atleastOneFilled } from "../../../../utils";

const defaultValue = {
  emotions: "",
  thought_before_emotion: "",
  how_accurate: "",
  change_though_to_more_accurate_thought: "",
};

const schema = yup.object().shape({
  emotions: yup.string().required(),
  thought_before_emotion: yup.string().required(),
  how_accurate: yup.string().required(),
  change_though_to_more_accurate_thought: yup.string().required(),
});

const ThreeCSToolPreview = React.forwardRef((props, ref) => {
  const { user } = useSelector((state) => state?.userReducer);
  const [isSelected, setIsSelected] = useState(false);
  const {t} = useTranslation();
  const tooltipStyle = {
    backgroundColor: "red", // Change this to your desired color
    color: "white", // Change this to the text color you want
  };

  const dispatch = useDispatch();

  const showToast = (message, type) => {
    dispatch(
      pushToast({
        message,
        type,
      })
    );
  };

  const OnSubmitHandler = async (values) => {
    setLoading(true);
    const response = api.storeThreeCSExercise({
      ...values,
      user_device_token: user?.user_device_token ?? user.device_token,
      status: values.status ?? "COMPLETED",
      bot_session_id: props?.sessionId,
    });
    setLoading(false);

    if (response) {
      props?.FetchTool && props.FetchTool();
      // showToast(t("tool_added_success"), t("success"));
      props?.closeModal(modals.MODAL_3CS_PREVIEW);
      setLoading(false);
      return true;
    } else {
      setLoading(false);
      showToast(t("something_went_wrong"), t("error"));
      return false;
    }
  };

  const { handleChange, values, errors, submitForm, validateForm } = useFormik({
    initialValues: props.selectedItem ?? defaultValue,
    onSubmit: OnSubmitHandler,
    validationSchema: schema,
    validateOnMount: true,
  });

  const saveForLater = () => {
    let payload = { ...values };
    const valid = atleastOneFilled(payload);
    if(valid) {
      payload.status = "PARTIAL_COMPLETED";
      OnSubmitHandler(payload);
    } else {
      validateForm();
      setIsSelected(true);
    }
  };

  const [loading, setLoading] = React.useState(false);
  const percentage = ((values.how_accurate / 100) * 100).toFixed(0);
  React.useImperativeHandle(ref, () => ({
    saveForLater
  }));

  return (
    <div className="modal-body modal-body-scr-4 _3cstool">
      <div className="d-flex justify-content-center">
        <div className="modal-text-scrn-4 mb-30">
          <div>
            😐
            <span className="modal-scrn-4-top-heading">
              {t("section_1_emotion")} <span className="text-danger">*</span>
            </span>
          </div>
          <div className="model-scrn-4-inner-content">
            <TextArea
              name={"emotions"}
              value={values.emotions}
              disabled={props.mode === toolAction.PREVIEW}
              // error={"This is a required field"}
              onChange={handleChange}
            />
            {errors.emotions && isSelected && (
              <div className="invalid-feedback d-block">
                {t("this_field_required")}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="modal-text-scrn-4 mb-30">
          <div>
            💭
            <span className="modal-scrn-4-top-heading">
              {t("section_2_catch_thought")}
              <span className="text-danger">*</span>
            </span>
          </div>
          <div className="model-scrn-4-inner-content">
            <TextArea
              name={"thought_before_emotion"}
              value={values.thought_before_emotion}
              disabled={props.mode === toolAction.PREVIEW}
              // error={"This is a required field"}
              onChange={handleChange}
            />
            {errors.thought_before_emotion && isSelected && (
              <div className="invalid-feedback d-block">
                {t("this_field_required")}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div className="modal-text-scrn-4 mb-30">
          <div>
            ✅
            <span className="modal-scrn-4-top-heading">
              {t("section_3_check_accuraccy")}
              <span className="text-danger">*</span>
            </span>
          </div>
          <div className="model-scrn-4-inner-content">
            <div id="chat-input">
              <Slider
                defaultValue={50}
                onChange={handleChange}
                name="how_accurate"
                aria-label="Default"
                value={values.how_accurate}
                valueLabelDisplay="auto"
                disabled={props.mode === toolAction.PREVIEW}
                TooltipComponent={(props) => (
                  <Tooltip {...props} style={tooltipStyle} />
                )}
              />
              <div className="percentage-display">
                {percentage}%
            </div>
              {errors.how_accurate && isSelected && (
              <div className="invalid-feedback d-block">
                {t("this_field_required")}
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="d-flex justify-content-center">
        <div className="modal-text-scrn-4 mb-30">
          <div>
            👍
            <span className="modal-scrn-4-top-heading">
              {t("section_4_provide_more_accuraccy")}
              <span className="text-danger">*</span>
            </span>
          </div>
          <div className="model-scrn-4-inner-content">
            <TextArea
              name="change_though_to_more_accurate_thought"
              value={values.change_though_to_more_accurate_thought}
              disabled={props.mode === toolAction.PREVIEW}
              // error={"This is a required field"}
              onChange={handleChange}
            />
            {errors.change_though_to_more_accurate_thought && isSelected && (
              <div className="invalid-feedback d-block">
                {t("this_field_required")}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-footer-wrapper">
        <div className="bg-white d-flex justify-content-around modal-footer">
          {loading ? (
            <p>{t("loading_text")}</p>
          ) : (
            <>
            <button
              type="button"
              style={{ width: "48%" }}
              className="btn  btn-purple h-3 toolbox-add-btn"
              onClick={() => {
                setIsSelected(true)
                // setFieldValue("status", "COMPLETED");
                submitForm();
              }}
            >
              {t("submit")}
            </button>
            <button
                    type="button"
                    style={{ width: "48%" }}
                    className="btn  btn-purple h-3 toolbox-add-btn"
                    onClick={async () => {
                      setIsSelected(true)
                      saveForLater();
                    }}
                  >
                    {t("save_for_later")}
                  </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default ThreeCSToolPreview;
