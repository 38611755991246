// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // Common
  loading_text: "Loading",
  this_field_required: "This field is required",
  enter_valid_phone: "Enter valid mobile number",
  otp_must_six_digit: "OTP must be 6 digits",
  submit: "Submit",
  save_for_later: "Save for later",
  completed: "COMPLETED",
  completed_lo: "Completed",
  tool_added_success: "Tool added successfully.",
  success: "success",
  error: "error",
  next: "Next",
  discard: "Discard",
  something_went_wrong: "Something went wrong. Try again later.",
  save_for_later_asking: "Do you want to save this for later ?",
  behavioral_activation: "Behavioral Activation",
  // Login Screen
  login_sc_header: "Login or Register",
  login_sc_header_part2: "to your account",
  login_sc_send_otp_button: "Send One Time Password",
  login_sc_signin_button: "Sign In",
  login_sc_sending_otp_text: "Sending OTP",
  login_sc_otp_text: "One Time Password",
  language: "Language",
  //Home Page
  chat_menu_title: "Chat",
  chat_menu_subtitle:
    "6 weeks empathetic, trained chatbot to help you improve your mental health",
  toolbox_menu_title: "Toolbox",
  toolbox_menu_subtitle: "Exercises and tools to practice regularly",
  depression_level: "Mid Depression",
  //chat header
  chat_heading: "Chat",
  toolbox_heading: "Toolbox",
  //EXERCISES:
  //emotion
  section_1_emotion: "Emotions",
  section_2_catch_thought: "Catch the thought before emotion",
  section_3_check_accuraccy: "Check how accurate do you think the thought is",
  section_4_provide_more_accuraccy:
    "Change the thought to a more accurate thought",
  //Cognitive Distortions:
  cog_title: "Cognitive Distortions",
  current_situation: "What is your current situation or context?",
  negative_thought: "Write down the negative thought",
  clue_for_answer:
    "Please select the cognitive distortion that you identified when answering the previous question",
  overgeneralization: "Overgeneralization",
  overgeneralization_elaboration:
    "Drawing broad conclusions based on isolated incidents",
  magnification: "Magnification",
  magnification_elaboration:
    "Exaggerating the importance or consequences of an event",
  emotional_reasoning: "Emotional Reasoning",
  emotional_reasoning_elaboration: "Believing that feelings reflect reality",
  personalization: "Personalization/Blame",
  personalization_elaboration:
    "Holding yourself responsible/blaming others for something that is not entirely in your/their control",
  should_statement: "Should Statements",
  should_statement_elaboration:
    "Focusing on how things should be based on your expectations",
  catastrophizing: "Catastrophizing",
  catastrophizing_elaboration: "Always expecting the worst-case scenario",
  fortune_telling: "Fortune Telling",
  fortune_telling_elaboration:
    "Making predictions/conclusions with little evidence",
  all_or_nothing: "All or Nothing Thinking ",
  all_or_nothing_elaboration:
    "Thinking in extremes Ex: Either I’m a success or a failure",
  labelling: "Labelling",
  labelling_elaboration:
    "Assigning judgements or “labels” to ourselves or someone else based on one experience",
  mind_reading: "Mind Reading",
  mind_reading_elaboration:
    "Assuming that you know what someone else is thinking",
  cog_more_accurate_thought: "Alternate or more accurate thought?",
  after_feeling:
    "How are you feeling after identifying your cognitive distortion? ",
  only_two_selection_error: "You can't select more than two.",
  //Behavioral Activation:
  task_title: "Task Title",
  task_description: "Task Desciption",
   new_reminder: "+ New Reminder",
  add_reminder_here: "Your reminders will be added here",
  task_title_ex: "Task Title Ex: Go shopping with Ramesh",
  task_description_ex: "Task description Ex: Buy food and toiletries",
  choose_active_type: "Choose the activity type",
  self: "Self",
  work: "Work",
  social: "Social",
  other: "Other",
  time: "Time",
  date: "Date",
  schedule: "Schedule",
  type: "Type",
  mark_as_done:
    "Check the box to mark as done. Please be aware that this action will remove your reminder",
  //Resilience Exercise:
  question: "Question",
  resilience_title: "Resilience Exercise",
  recent_example: "Recall a recent example of resilience",
  recent_example_elaboration:
    "Think about a time recently when you overcome a challenge or set back in life. Perhaps you injured yourself, or received some negative feedback at work, or had an argument with a friend or family member. Briefly describe the difficult below.",
  identify_support: "Identify supportive people",
  identify_support_elaboration:
    "What ‘supportive people’ in your life kept you standing when it would have been easier to fall down? For instance, did you call an old friend, or ask a teacher for advice, or perhaps a parent or grandparent gave you a pep talk. Write down who you called on for support",
  identify_strategies: "Identify Strategies",
  identify_strategies_elaboration:
    "What ‘strategies’ did you use to help yourself cope with any negative thoughts and feelings that showed up in response to the difficulty? For example, did you meditate, or write a gratitude journal, or go for a walk, or listen to a particular song or type of music, or have a massage to release tension. Write down the strategies you used",
  identify_sagacity: "Identify sagacity",
  identify_sagacity_elaboration:
    "What ‘sagacity’ helped you bounce back from this difficulty? Sagacity is the wisdom and insight that you hold onto. It can come from song lyrics, novels, poetry, spiritual writings, quotes from famous people, the sayings of one’s grandparent, or learning from ones own experiences. Write down your sagacity below",
  identify_behaviour: "Identify solution-seeking behaviors",
  identify_behaviour_elaboration:
    "What solution-seeking behaviors did you display to help you actively deal with the problem? For example, did you problem-solve, or seek out new information, or plan ahead, or negotiate, or speak up and voice your opinion, or ask others for help. Write down the solution-seeking behaviors you displayed",
  //thought restructuring
  thought_restructure_title: "Thought Restructuring",
  negative_label: "Negative",
  neutral_label: "Neutral",
  //list tools
  assesment: "Assesment",
  behavioural_activation : "Behavioural Activation",
  threeCS_exercise: "3CS Exercise",
  add_entry: "Add Entry",
  list_title: "3CS List",
  attempts: "Attempts",
  week: "Week",
  partially: "Partially",
  //Settings
  settings: "Settings",
  user_name: "User Name",
  dark_mode: "Dark Mode",
  about_us: "About Us",
  subscriptions: "Subscriptions",
  logout: "Logout",
  // subscription page
  monthly: "Monthly",
  subscription_plan : "Subscription Plan",
  yearly : "Yearly",
  not_pay_anything : "You don't pay anything for now!!",
  full_access_msg : "Full access, no limitations!!",
  proceed : "Proceed!",
  what_would_you: "What would you like to be called?",
  your_name: "Your Name",
  continue: "Continue",
  moderate: "Moderate",
  negligible: "Negligible",
  mild: "Mild",
  severe: "Severe",
  depression: "Depression",
  anxiety: "Anxiety",
  booked_h1: "Upcoming Appointment",
  booked_btn: "Go to Appointment",
  book_now_h1: "Let's Navigate",
  book_now_h2: "Through This Together",
  book_now_t: "Empathy, support, and expertise await",
  book_now_btn: "Book Now",
  Recommended: "Recommended",
  book_session: "Book Session",
  appointment_or_booking: "Appointment Or Booking",
  ongoing: "Ongoing",
  previous: "Previous",
  appointment_going_on: "Please book next appointment when current appointment is over"
};
