import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { css } from "@emotion/css";
import ScrollToBottom from "react-scroll-to-bottom";
import api from "../../../api";

import "../../../styles/assessment.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "../../../routes";
import { useDispatch } from "react-redux";
import { pushToast } from "../../../redux/slices/toastSlice";
import { t } from "i18next";
import { setUser } from "../../../redux/slices/userSlice";

const ROOT_CSS = css({
  height: "100vh",
  width: "100%",
});

const Assessment = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.userReducer);
  const [assessmentQuestions, setAssessmentQuestions] = React.useState([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] =
    React.useState(undefined);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [disableOptions, setDisableOptions] = useState(false);

  const handleFetchAssessmentData = useCallback(async () => {
    try {
      const res = await api.getAssesment();
      const data = await res.json();
      let result;
      if(localStorage.getItem("lang") === "hi" || !localStorage.getItem("lang")) {
        const firstQuestion = data.findIndex((val) => !Boolean(val.parent) && val.type === "issue");
        result = [
          data[firstQuestion],
          ...data.slice(0, firstQuestion),
          ...data.slice(firstQuestion + 1)
        ]
      } else {
        result = data;
      }
      setAssessmentQuestions(result);
    } catch (e) {
      console.log("Error " + e);
    }
  }, []);

  const scrollableRef = useRef(null);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (assessmentQuestions.length > 0 && selectedQuestionIndex === undefined) {
      setSelectedQuestionIndex(0);
    }
  }, [assessmentQuestions]);

  const dispatch = useDispatch();

  const showToast = (message, type) => {
    dispatch(
      pushToast({
        message,
        type,
      })
    );
  };

  const onAssesmentSubmitHandler = async (lastAnswerPayload) => {
    const payload = {
      user_device_token: user?.user_device_token ?? user?.device_token,
      answer: [...selectedAnswer, lastAnswerPayload],
    };

    payload.gender = payload.answer[payload.answer.length - 1].answer;
    payload.age = payload.answer[payload.answer.length - 2].answer; 
    payload.lang_id = localStorage.getItem("lang") ?? 2;

    try {
      const response = await api.saveAssesment(payload);
      if (!response) {
        // showToast("Something went wrong. Try again later.", "error");
      } else {
        const loggedInUser = await api.getCurrentUser({
          device_token: user.device_token
        })
        const {data} = await loggedInUser.json(); 
        dispatch(setUser({...data}));
        navigate(routes.SUBSCRIPTION);
        // showToast("Assesment created successully", "success");
      }
    } catch (e) {
      // showToast("Something went wrong. Try again later.", "error");
    }
  };

  const onOptionClickHandler = (option, selectedQuestionIndex, optionIndex) => {
    if (selectedQuestionIndex === 0) {
      const selectedOptionId = String(optionIndex + 1);
      const questionsToBeAsked = assessmentQuestions.filter(
        (ques) => ques.parent === selectedOptionId
      );
      const questionsToBeAskedAtEnd = assessmentQuestions.filter(
        (ques, index) => index !== 0 && !Boolean(ques.parent)
      );
      setAssessmentQuestions((ques) => [
        ques[0],
        ...questionsToBeAsked,
        ...questionsToBeAskedAtEnd,
      ]);
    }
    const answerPayload = {
      question_id: assessmentQuestions[selectedQuestionIndex]?.id,
      question: assessmentQuestions[selectedQuestionIndex]?.question,
      answer: option.label,
      answer_id: option.id,
      seq: assessmentQuestions[selectedQuestionIndex]?.seq,
    };
    if (
      selectedQuestionIndex > 0 &&
      selectedQuestionIndex === assessmentQuestions.length - 1
    ) {
      onAssesmentSubmitHandler(answerPayload);
      setDisableOptions(true);
    } else {
      setSelectedAnswer((answer) => [...answer, answerPayload]);
      setSelectedQuestionIndex(selectedQuestionIndex + 1);
    }
  };

  useEffect(() => {
    handleFetchAssessmentData();
  }, []);

  return (
    <div className="adjust-to-middle">
      <ScrollToBottom className={ROOT_CSS} ref={scrollableRef}>
        <div className="container assessment-container-wrapper">
          {selectedAnswer.map((answer) => (
            <div>
              <div className="main-question">{answer?.question}</div>
              <div className="answer-option">{answer?.answer}</div>
            </div>
          ))}
          <div className="">
            {assessmentQuestions?.length > 0 ? (
              <div className="outerWrapper">
                <div className="content">
                  <div className="main-question mt-5">
                    {assessmentQuestions[selectedQuestionIndex]?.question}
                  </div>
                </div>
                <ul className="text-center li-style-none p-0">
                  {disableOptions ? (
                    <p>{t("loading_text")}</p>
                  ) : (
                    assessmentQuestions?.[selectedQuestionIndex]?.options?.map(
                      (option, index) => (
                        <li key={option.id}>
                          <button
                            disabled={disableOptions}
                            className="assign-button mb-3"
                            onClick={() =>
                              onOptionClickHandler(
                                option,
                                selectedQuestionIndex,
                                index
                              )
                            }
                          >
                            {option?.label}
                          </button>
                        </li>
                      )
                    )
                  )}
                </ul>
              </div>
            ) : (
              <div>{t("loading_text")}</div>
            )}
          </div>
        </div>
      </ScrollToBottom>
    </div>
  );
};

export default Assessment;
