import React, { useCallback, useState, useEffect, useRef } from "react";
import { css } from "@emotion/css";
import ScrollToBottom from "react-scroll-to-bottom";
import api from "../../../../api";
import "../../../../styles/assessment.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "../../../../routes";
import { useDispatch } from "react-redux";
import { pushToast } from "../../../../redux/slices/toastSlice";
import ChatHeader from "../../ChatHeader";
import { useTranslation } from "react-i18next";

const ReAssessment = ({ botSession, onCloseHandler }) => {
  const ROOT_CSS = css({
    height: botSession ? "100vh" : "75vh",
    width: "100%",
    position: "relative"
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.userReducer);
  const [assessmentQuestions, setAssessmentQuestions] = React.useState([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] =
    React.useState(undefined);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [disableOptions, setDisableOptions] = useState(false);

  const handleFetchAssessmentData = useCallback(async () => {
    try {
      const res = await api.getAssesment();
      const data = await res.json();

      const submitedAssesment = await api.fetchSubmittedAssesment({
        user_device_token: user?.user_device_token ?? user?.device_token,
      });

      const submitedAssesmentResponse = await submitedAssesment.json();
      const lastResponseId = submitedAssesmentResponse?.data?.answer_id;
      const firstQuestion = data.find((val) => !Boolean(val.parent) && val.type === "issue");
      const firstResponseIndex = firstQuestion.options.findIndex((val) => val.id === lastResponseId);
      setAssessmentQuestions(
        data.filter((res) => res.parent === String(firstResponseIndex + 1) && res.type === "score")
      );
    } catch (e) {
      console.log("Error " + e);
    }
  }, []);

  const scrollableRef = useRef(null);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (assessmentQuestions.length > 0 && selectedQuestionIndex === undefined) {
      setSelectedQuestionIndex(0);
    }
  }, [assessmentQuestions]);

  const dispatch = useDispatch();

  const showToast = (message, type) => {
    dispatch(
      pushToast({
        message,
        type,
      })
    );
  };

  const onAssesmentSubmitHandler = async (lastAnswerPayload) => {
    const payload = {
      user_device_token: user?.user_device_token ?? user.device_token,
      answer: [...selectedAnswer, lastAnswerPayload],
    };

    try {
      const response = await api.saveReAssesment(payload);
      if (!response) {
        showToast("Something went wrong. Try again later.", "error");
      } else {
        if(onCloseHandler) {
          onCloseHandler?.();
        } else {
          navigate(routes.HOME_ROUTE);
        }
        // showToast("Assesment created successully", "success");
      }
    } catch (e) {
      showToast("Something went wrong. Try again later.", "error");
    }
  };

  const onOptionClickHandler = (option, selectedQuestionIndex, index) => {
    const answerPayload = {
      question_id: assessmentQuestions[selectedQuestionIndex]?.id,
      question: assessmentQuestions[selectedQuestionIndex]?.question,
      answer: option.label,
      answer_id: option.id,
      seq: assessmentQuestions[selectedQuestionIndex]?.seq,
      index: index,
    };
    if (selectedQuestionIndex === assessmentQuestions.length - 1) {
      onAssesmentSubmitHandler(answerPayload);
      setDisableOptions(true);
    } else {
      setSelectedAnswer((answer) => [...answer, answerPayload]);
      setSelectedQuestionIndex(selectedQuestionIndex + 1);
    }
  };

  useEffect(() => {
    handleFetchAssessmentData();
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      {!botSession && (
        <div className="modal-header-wrapper" style={{margin: 0, padding: 0, width: 'auto', height: '25vh'}}>
          <ChatHeader />
          <div className="d-flex justify-content-start align-items-center m-3">
            <i
              onClick={() => {
                navigate(-1);
              }}
              className="cursor-pointer fa-sharp fa-solid fa-arrow-left color-purple toolbox-navigate-prev"
            ></i>
            <div className="toolbox-heading">Assessment</div>
          </div>
          <hr className="hr-toolbox" />
        </div>
      )}
     
        <div className="modal-body modal-body-scr-4 d-flex justify-content-center" style={{overflow: 'hidden'}}>
          <ScrollToBottom className={ROOT_CSS} ref={scrollableRef}>
            <div
              className="container assessment-container-wrapper"
              style={{position: 'absolute'}}
              // style={{ overflow: "scroll", height: "74%" }}
            >
              {selectedAnswer.map((answer) => (
                <div>
                  <div className="main-question">{answer?.question}</div>
                  <div className="answer-option">{answer?.answer}</div>
                </div>
              ))}
              <div className="">
                {assessmentQuestions?.length > 0 ? (
                  <div className="outerWrapper">
                    <div className="content">
                      <div className="main-question mt-5">
                        {assessmentQuestions[selectedQuestionIndex]?.question}
                      </div>
                    </div>
                    <ul className="text-center li-style-none p-0">
                      {disableOptions ? (
                        <p>{t("loading_text")}</p>
                      ) : (
                        assessmentQuestions?.[
                          selectedQuestionIndex
                        ]?.options?.map((option, index) => (
                          <li key={option.id}>
                            <button
                              disabled={disableOptions}
                              className="assign-button mb-3"
                              onClick={() =>
                                onOptionClickHandler(
                                  option,
                                  selectedQuestionIndex,
                                  index
                                )
                              }
                            >
                              {option?.label}
                            </button>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                ) : (
                  <div>{t("loading_text")}</div>
                )}
              </div>
            </div>
          </ScrollToBottom>
        </div>
    
    </div>
  );
};

export default ReAssessment;
