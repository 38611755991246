import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import routes from "../../routes";

export const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((state) => state?.userReducer);
  const { pathname } = useLocation(); 
  if (!user?.device_token) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  
  if(!user?.mh_assessment && pathname !== "/assesment") {
    return <Navigate to={routes.ASSESMENT} />;
  }
  
  return children;
};
