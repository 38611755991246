import React from "react";
import { useTranslation } from "react-i18next";

const LoginHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="top-section adjust-to-middle">
        <div className="top-section-header top-section-w1">
          <span className="font-600">{t("login_sc_header")}</span>
          {/* <span className="font-300">{t("login_sc_header_part2")}</span> */}
        </div>
      </div>
    </>
  );
};

export default LoginHeader;
