import React from "react";
import ModeSwitch from "../common/mode-switch";
import "../../styles/settings.css";
import { useNavigate } from "react-router-dom";
import routes from "../../routes";
import { useDispatch } from "react-redux";
import { clearUser, setResetUser } from "../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { setResetChat } from "../../redux/slices/chatSlice";

const UserSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((user) => user.userReducer);
  const { t } = useTranslation();
  const theme = (createTheme) => ({
    palette: {
      mode: "light", // Default mode
    },
  });

  const onSubscriptionHandler = () => {
    navigate(routes.SUBSCRIPTION);
  };

  const onLogoutClickHandler = () => {
    dispatch(setResetUser());
    dispatch(setResetChat());
    navigate(routes.LOGIN);
  };

  const onAppointmentClickHandler = () => {
    navigate(routes.APPOINTMENT);
  }

  const getCurrentWeek = () => {
    const {currentweek} = user;
    if(!isNaN(currentweek)) {
      return parseInt(currentweek) + 1;
    }
  }

  return (
    <>
      <div className="adjust-to-middle">
        <div className="top-header settings-container">
          <div className="page-header-container">
            <div className="row w-100">
              <div className="col-4">
                <i
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="fa-solid fa-arrow-left ft-2"
                ></i>
              </div>
              <div className="col-4 text-center">
                <img src={require("../../logo.jpeg")} alt="" height={"50px"} />
              </div>
              <div className="col-4" style={{ position: "relative" }}>
                <span
                  style={{ position: "absolute", bottom: "22px" }}
                  className="page-title-chat page-title"
                >
                  {t("settings")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="user-info-container">
          <div className="d-flex justify-content-between align-items-center w-100 padding-0-30">
            <div className="user-avatar"></div>
            <div className="d-flex flex-column justify-content-start align-items-center lh-38">
              <span className="user-name-label">{user.name}</span>
              <span className="user-email-label">{user.phone}</span>
            </div>
            <div>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
          </div>
        </div>
        <div className="settings-element">
          <div className="d-flex justify-content-between align-items-center lh-38">
            <span>{t("week")}</span>
            <span>
              {getCurrentWeek()} 
            </span>
          </div>
        </div>
        <div className="settings-element" onClick={onAppointmentClickHandler}>
          <div className="d-flex justify-content-between align-items-center lh-38">
            <span>{t("appointment_or_booking")}</span>
          </div>
        </div>
        <div className="settings-element" onClick={onSubscriptionHandler}>
          <div className="d-flex justify-content-between align-items-center lh-38">
            <span>{t("subscriptions")}</span>
          </div>
        </div>
        <div className="settings-element" onClick={onLogoutClickHandler}>
          <div className="d-flex justify-content-between align-items-center lh-38">
            <span>{t("logout")}</span>
            <i class="fa-solid fa-arrow-right-from-bracket"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettings;
