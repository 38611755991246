import React, { useEffect, useRef, useState } from "react";
import LoginFooter from "./LoginFooter";
import LoginHeader from "./LoginHeader";
import LoginPhone from "./LoginPhone";
import { auth, firebase } from "../../firebase";
import * as yup from "yup";
import { validatePhoneNumber } from "../../utils";
import { useFormik } from "formik";
import { parsePhoneNumber } from "react-phone-number-input";
import api from "../../api";
import SignUpOTP from "../signup/SignUpOTP";
import { useDispatch } from "react-redux";
import { setFirebaseUser, setNewUser, setUser } from "../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "../../routes";
import { pushToast } from "../../redux/slices/toastSlice";
import { useTranslation } from 'react-i18next';
import { OTP_MUST_SIX_DIGITS, REQUIRED_MESSAGE_ERROR, VALID_PHONE_NUMBER } from "../../constant/error-messages";
import { storeAuthDetails } from "../../service/authService";

const initialValues = {
  phone: "",
  otp: "",
};

const schema = yup.object({
  phone: yup
    .string()
    .required(REQUIRED_MESSAGE_ERROR)
    .test("phone-test", VALID_PHONE_NUMBER, (val) =>
      validatePhoneNumber(val)
    ),
  otp: yup
    .string()
    .length(6, OTP_MUST_SIX_DIGITS)
    .required(REQUIRED_MESSAGE_ERROR),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state?.userReducer);
  const { t, i18n } = useTranslation();

  const [loader, setLoader] = React.useState(false);
  const [final, setFinal] = React.useState(null);
  const [step, setStep] = useState(1);
  const [timer, setTimer] = useState(60); // Initial timer value in seconds
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  useEffect(() => {
    let interval;

    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isTimerRunning]);

  useEffect(() => {
    if (timer === 0) {
      setIsTimerRunning(false);
      setTimer(60); // Reset the timer to initial value after reaching 0
    }
  }, [timer]);

  const handleResendClick = () => {
    if (!isTimerRunning) {
      setIsTimerRunning(true);
    }
  };

  const [otpSubmittedClicked, setOtpSubmittedCliked] = React.useState(false);

  useEffect(() => {
    if (user?.device_token) {
      navigate(routes.HOME_ROUTE);
    }
  }, []);

  const showToast = (message, type) => {
    dispatch(pushToast({
      message,
      type
    }))
  };
 
  const submitHandler = async (e) => {
    setLoader(true);
    try {
      const confirm = await final.confirm(values.otp);
      if (confirm) {
        const parsedNumber = parsePhoneNumber(`+${values.phone}`);
        dispatch(setFirebaseUser(confirm.user));
        const payload = {
          phone: parsedNumber.nationalNumber,
          country_code: parsedNumber?.countryCallingCode || "",
        };

        const phoneNumberExist = await api.phoneNumberExist({
          ...payload
        });
        const phoneNumberExistResponse = await phoneNumberExist.json();
        if (phoneNumberExistResponse.status !== "success") {
          dispatch(setNewUser(payload))
          navigate(routes.SIGNUP_NAME);
        } else {
          const saveUser = await api.login(payload);
          if (saveUser) {
            // showToast("User logged in successfully.", "success");
            const response = await saveUser.json();
            storeAuthDetails(response.accessToken, response.refreshToken);
            dispatch(setUser({ ...user, ...response.user }));
            if (response.user?.assesmentGiven) {
              navigate(routes.SUBSCRIPTION, {
                state: {
                  loginAllowed: true,
                }
              });
            } else {
              navigate(routes.ASSESMENT);
            }
          } else {
            // showToast("Something went wrong. Try again later.", "error");
            setLoader(false);
          }
        }
      } else {
        // showToast("Something went wrong. Try again later.", "error");
        setLoader(false);
      }
    } catch (e) {
      if (e?.code === "auth/invalid-verification-code") {
        // showToast("Incorrent OTP.", "error");
      } else {
        // showToast("Something went wrong. Try again later.", "error");
      }

      setLoader(false);
    }
  };

  const { handleChange, values, errors, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      onSubmit: submitHandler,
      validationSchema: schema,
    });

  const nextStep = () => {
    setStep((prevStep) => {
      const updatedStep = prevStep + 1;
      return updatedStep;
    });
  };

  const prevStep = () => {
    setStep((prevStep) => {
      const updatedStep = prevStep - 1;
      return updatedStep;
    });
  };

  const verifyRef = useRef();

  const onPhoneNumberSetHandler = async () => {
    setLoader(true);
    const mynumber = values.phone;
    if (mynumber === "" || mynumber.length < 10) return;
    if (!verifyRef.current) {
      verifyRef.current = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("response", response);
        },
      });
    }

    if (step === 1) {
      handleResendClick();
    }

    auth
      .signInWithPhoneNumber("+" + mynumber, verifyRef.current)
      .then((result) => {
        // showToast("OTP Sent successfully.", "success");
        setFinal(result);
        setLoader(false);
        if (step === 1) {
          nextStep();
        }
      })
      .catch(() => {
        // showToast("Something went wrong. Try again later.", "error");
        setLoader(false);
      });
  };


  const changeanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  return (
    <div className="">
      <LoginHeader></LoginHeader>
      <div id="recaptcha-container"></div>
      <div className="form-group bottom-form">
        {step === 1 && (
          <LoginPhone
            errors={errors}
            formData={values}
            setFieldValue={setFieldValue}
          />
        )}

        {step === 2 && (
          <SignUpOTP
            otpSubmittedClicked={otpSubmittedClicked}
            formData={values}
            errors={errors}
            resendOtp={onPhoneNumberSetHandler}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )}

        {step === 2 && (isTimerRunning ? `Resend otp in ${timer} seconds` : step === 2 && <div className="row mb-3">
          <b style={{
            color: '#974ce8'
          }} onClick={() => {
            onPhoneNumberSetHandler();
            handleResendClick();
          }}>Resend OTP</b>
        </div>)}
        {/* <div className="row mb-3">
          <label>{t("language")} : </label>
          <select
            defaultValue={i18n.language}
            className="form-control"
            onChange={(e) => changeanguageHandler(e.target.value)}
          >
            <option value={"hi"}>हिंदी</option>
            <option value={"en"}>English</option>
          </select>
        </div> */}
        {!loader ? (
          <button
            type="submit"
            className="btn  btn-purple h-3 fw-200"
            onClick={(e) => {
              handleSubmit(e);
              if (step === 1 && !errors.phone && Boolean(values.phone)) {
                onPhoneNumberSetHandler();
              }

              if (step === 2) {
                setOtpSubmittedCliked(true);
              }
            }}
          >
            {step === 1
              ? t("login_sc_send_otp_button")
              : t("login_sc_signin_button")}
          </button>
        ) : (
          <p>
            {step === 1 ? t("login_sc_sending_otp_text") : t("loading_text")}
          </p>
        )}
        <p className="mt-4 text-center">For now available in India</p>
        {/* <LoginFooter /> */}
      </div>
    </div>
  );
};

export default Login;
