import React, { useEffect, useRef, useState } from "react";
import ChatHeader from "../../ChatHeader";
import Modal from "../../../Modal";
import AddNewRecord from "./AddNewRecord";
import modals from "../../../../modals";
import ToolPreviewModal from "../../../common/tool-preview-modal/ToolPreviewModal";
import toolAction from "../../../../constant/tool-action";
import api from "../../../../api";
import { useSelector } from "react-redux";
import ToolListHeader from "../../../common/tool-list/ToolListHeader";
import { useTranslation } from "react-i18next";

const BehaviouralRecords = React.forwardRef(() => {
  const { user } = useSelector((state) => state?.userReducer);
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = React.useState();
  const [modalOpen, setModalOpen] = useState(false);
  const {t} = useTranslation();

  const openModal = (modalId) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [modalId]: true,
    }));
  };

  const closeModal = (modalId) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [modalId]: false,
    }));
  };

  const FetchTool = () => {
    (async () => {
      const response = await api.fetchBehavioralList({
        user_device_token: user?.user_device_token ?? user?.device_token,
      });
      const list = (await response.json()).data;
      setList(list);
      console.log(list);
    })();
  };

  useEffect(() => {
    FetchTool();
  }, []);

  const [mode, setMode] = React.useState(toolAction.PREVIEW);

  const behavioralRefordRef = useRef();

  return (
    <>
      <ChatHeader />
      <div className="modal-header-wrapper">
        <ToolListHeader
          buttonClickHandler={() => {
            openModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW);
            setMode(toolAction.CREATE);
          }}
          buttonText={t("new_reminder")}
          title={t("behavioral_activation")}
        />
        <hr className="hr-toolbox" />

        <div className="m-center">
          <div className="removal-reminder">
            <div className="d-flex justify-content-between align-items-center inner-removal-container">
              <span className="reminder-text">
                {t("mark_as_done")}
              </span>
              <span>
                <i className="fa-solid fa-circle-xmark reminder-note-cross-icon"></i>
              </span>
            </div>
          </div>
        </div>
        {list.map((item, key) => (
          <div key={`item-${key}`} className="m-center">
            <div className="radio-option-containers p-12 mb-15 schedule-cart">
              <div className="d-flex justify-content-between">
                <b>{item.task_title}</b>
                <div className="form-check">
                  <input className="custom-control-input" type="checkbox" />
                </div>
              </div>
              <div>
                <table className="w-100 mt-4 schedule-headings">
                  <tr className="record-light">
                    <td>{t("date")}</td>
                    <td>{t("time")}</td>
                    <td className="text-end">{t("type")}</td>
                  </tr>
                  <tr className="record-dark">
                    <td>{item.date}</td>
                    <td>{item.time}</td>
                    <td className="text-end">
                      <div className="self-reminder"></div>
                      {item.actionType}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        ))}

        <ToolPreviewModal
          isOpen={modalOpen?.[modals.MODAL_BEHAVIORAL_ACTION_PREVIEW]}
          onCloseClickHandler={() => {
            if (mode !== toolAction.PREVIEW) {
              openModal(modals.MODAL_SAVE_DISCARD);
            } else {
              closeModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW);
            }
          }}
        >
          <div className="modal-header-wrapper w-344">
            <div className="d-flex justify-content-start align-items-center bg-white modal-header p-0">
              {/* <i className="cursor-pointer fa-sharp fa-solid fa-arrow-left color-purple toolbox-navigate-prev"></i> */}
              <div className="toolbox-heading">{t("schedule")}</div>
            </div>
          </div>
          <AddNewRecord
            selectedItem={selectedItem}
            closeModal={closeModal}
            mode={mode}
            ref={behavioralRefordRef}
            FetchTool={FetchTool}
          />
        </ToolPreviewModal>

        <Modal isOpen={modalOpen.modalSaveDiscard}>
          <div className="modal-body">
            <div className="">
              <div className="modal-discard-save p--7">
                <div className="d-flex justify-content-center align-items-center fs-15">
                  {t("save_for_later_asking")}
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="button"
                    className="btn btn-purple-light  mt-3 h-3 w-100"
                    onClick={() => {closeModal(modals.MODAL_SAVE_DISCARD)
                    closeModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW)}}
                  >
                    {t("discard")}
                  </button>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="button"
                    className="btn btn-purple-light mt-3 h-3 w-100"
                    onClick={async () => {
                      await behavioralRefordRef.current.saveForLater();
                      closeModal(modals.MODAL_SAVE_DISCARD);
                    }}
                  >
                    {t("save_for_later")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
});

export default BehaviouralRecords;
