import axios from 'axios';
import routes from '../routes';


const BOT_BASE_URL = process.env.REACT_APP_BOT_BASE_URL;

const api = axios.create({
  baseURL: BOT_BASE_URL,
});
let isRefreshing = false;
let failedRequests = [];

api.interceptors.response.use((value) => {
  return {
      json: async () => {
          return value.data;
      }
  }
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if(error.config.url === "/refresh-token") {
      logout();
    } 

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const refreshToken = localStorage.getItem('refreshToken');

          if (!refreshToken) {
            console.log("!refreshToken")
            logout();
          }

          const response = await api.post('/refresh-token', { refreshToken });
          const data = await response.json();
          const newToken = data.accessToken;

          localStorage.setItem('accessToken', newToken);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          // Retry the original request with the new token
          return api(originalRequest);
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          // logout();
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        // If token is already being refreshed, queue the original request
        return new Promise((resolve, reject) => {
          failedRequests.push({ resolve, reject });
        }).then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return api(originalRequest);
        });
      }
    }

    return Promise.reject(error);
  }
);

function resolveFailedRequests(token) {
  failedRequests.forEach((request) => {
    request.resolve(token);
  });
  failedRequests = [];
}

function logout() {
  localStorage.clear();
  window.location.href = routes.LOGIN;
}

export default api;
