// eslint-disable-next-line import/no-anonymous-default-export
export default {
    MODAL_STATUS_ADD_NEW_COG : "modalStatusAddNewCog",
    MODAL_STATUS_COMPLETED : "modalStatusCompleted",
    MODAL_STATUS_PARTIAL : "modalStatusPartial",
    MODAL_SAVE_DISCARD : "modalSaveDiscard",
    MODAL_STATUS_ADD_RESILIENCE : "modalStatusAddResilience",
    MODAL_STATUS_ADD_NEW_3CS : "modalStatusAddNew3cs",
    MODAL_3CS_TOOL : "modal3CSTool",
    MODAL_STATUS_ADD_NEW_TR : "modalStatusAddNewTR",
    MODAL_3CS_PREVIEW: "MODAL_3CS_PREVIEW",
    MODAL_BEHAVIORAL_ACTION_PREVIEW: "MODAL_BEHAVIORAL_ACTION_PREVIEW",
    MODAL_ADD_NEW_SCHEDULE: "modalAddNewSchedule",
    MODAL_BEHAVIOURAL_RECORDS_CHAT: "modalBehaviouralRecordsChat",
    RE_ASSESMENT: "reAssesment",
}