import React from "react";
import HeaderFunction from "../common/HeaderFunctions";
import { useNavigate } from "react-router-dom";
import routes from "../../routes";
import { useTranslation } from "react-i18next";

const Subscriptions = () => {
  const navigate = useNavigate();
  const onSubscriptionSubmit = () => {
    navigate(routes.HOME_SCREEN);
  };
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="adjust-to-middle">
        <div className="top-header home-screen-header">
          <div className="page-header-container">
            <div>
              <span className="home-logo-img"></span>
            </div>
            <HeaderFunction />
          </div>
        </div>
        <div className="subscriptionConainer">
          <div className="d-flex justify-content-center">
            <p className="username-label text-center pt-40">
              {t("subscription_plan")}
            </p>
          </div>
          <div className="container mt-3">
            <div className="row">
              <div className="col-md-6">
                <div className="subscription-card">
                  <div className="card-header">
                    <h5 className="card-title text-center">{t("monthly")}</h5>
                  </div>
                  <div className="card-body">
                    <p className="card-text text-center mt-2">
                      <del>₹499.00 (US $6)</del>
                    </p>
                    <p className="card-text text-center mb-2"> ₹0 (US $0)</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="subscription-card">
                  <div className="card-header">
                    <h5 className="card-title text-center">{t("yearly")}</h5>
                  </div>
                  <div className="card-body">
                    <p className="card-text text-center mt-2">
                      <del>₹4,100.00 (US $50)</del>
                    </p>
                    <p className="card-text text-center mb-2"> ₹0 (US $0)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center ">
            <p className="username-sub-label">{t("not_pay_anything")}</p>
          </div>
          <div className="d-flex justify-content-center">
            <p className="username-sub-label"> {t("full_access_msg")} </p>
          </div>
          <div className="d-flex justify-content-center">
            <button className="username-button" onClick={onSubscriptionSubmit}>
              {" "}
              {t("proceed")}{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
