import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function ToolListHeader({ buttonText, buttonClickHandler, title }) {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="d-flex justify-content-start align-items-center m-3">
        <i
          onClick={() => {
            navigate(-1);
          }}
          className="cursor-pointer fa-sharp fa-solid fa-arrow-left color-purple toolbox-navigate-prev"
        ></i>
        <div className="toolbox-heading">{title}</div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <button
          type="button"
          className="btn  btn-purple h-3 toolbox-add-btn"
          onClick={() => buttonClickHandler()}
        >
          {buttonText}
        </button>
      </div>
    </React.Fragment>
  );
}
