import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { io } from 'socket.io-client';
import AgoraRTC, {
  AgoraRTCProvider,
  LocalUser,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
  useRTCClient,
} from "agora-rtc-react";
import './AgoraUI.css';
import api from "../../api";

export default function () {
  const location = useLocation();
  const { bookingId } = location?.state ?? { bookingId : 2 };
  const [callDetail, setCallDetail] = useState();
  const [show, setShow] = useState(false);

  const agoraClient = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: "rtc" }));

  const ENDPOINT = process.env.REACT_APP_WS_URL;
  const socket = io(ENDPOINT, {
    transports: ['websocket']
  });

  useEffect(() => {
    socket.on('connect', () => {
      console.log(true);
    });

    socket.on('disconnect', () => {
      console.log(false);
    });

    socket.on(`VIDEO_CALL-INITIATED/${bookingId}`, function(args) {
      if(args.session === bookingId && args.status === true) {
        GetAgoraSession();
      }
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, []);


  const GetAgoraSession = async () => {
    const response = await api.getVideoAgoraSessionData({
      sessionId: bookingId,
    });
    if(response.status === "success") {
      setCallDetail(response);
    }
  }

  useEffect(() => {
    if(bookingId) {
      GetAgoraSession();
    }
  }, [bookingId]);

  return (
    <React.Fragment>
      <AgoraRTCProvider client={agoraClient}>
        <div className="adjust-to-middle">
          <Header />
          {(callDetail && show) ? 
            <LiveVideo callDetail={callDetail}/> :
            <ShowLoaderScreen enableButton={Boolean(callDetail)} setShow={setShow}/>}
        </div>
      </AgoraRTCProvider>
    </React.Fragment>
  )
}

const LiveVideo = ({ callDetail }) => {
  const [showCallWindow, setShowCallWindow] = useState(false);
  // set the connection state
  const [activeConnection, setActiveConnection] = useState(true);

  // track the mic/video state - Turn on Mic and Camera On
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);

  // get local video and mic tracks
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);

  console.log({
    LiveCall: {
      appid: callDetail.apiKey,
      channel: callDetail.channel,
      token: callDetail.chatToken,
    }
  })
  // Join the channel
  useJoin(
    {
      appid: callDetail.apiKey,
      channel: callDetail.channel,
      token: callDetail.chatToken,
      uid: parseInt(callDetail.patient.id)
    },
    activeConnection,
  );

  usePublish([localMicrophoneTrack, localCameraTrack]);

  //remote users
  const remoteUsers = useRemoteUsers();
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);

  return (
    <div className="video-container">
      <div id='remoteVideoGrid'>
        {
          // Initialize each remote stream using RemoteUser component
          remoteUsers.slice(0, 1).map((user) => (
            <div key={user.uid} className="remote-video-container">
              <RemoteUser user={user} />
            </div>
          ))
        }
      </div>
      <div id='localVideo'>
        <LocalUser
          // audioTrack={localMicrophoneTrack}
          videoTrack={localCameraTrack}
          cameraOn={cameraOn}
          micOn={micOn}
          playAudio={micOn}
          playVideo={cameraOn}
          className='h-83'
        />
        <div>
          {/* media-controls toolbar component - UI controling mic, camera, & connection state  */}
          <div id="controlsToolbar">
            <div id="mediaControls">
              <button className="btn" onClick={() => setMic(a => !a)}>
                <i className={`fas ${micOn ? 'fa-microphone-alt bg-green' : 'bg-red fa-microphone-alt-slash'}`}></i>
              </button>
              <button className="btn" onClick={() => setCamera(a => !a)}>
                <i className={`fa-solid ${cameraOn ? 'fa-video bg-green' : 'bg-red fa-video-slash'}`}></i>
              </button>
              <button className="btn" id="endConnection" onClick={() => setActiveConnection(false)}>
                <i className={`fa ${activeConnection ? 'fa-phone-slash bg-green' : 'bg-red fa-phone'}`}></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Header = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="top-header settings-container" style={{minHeight: '10vh'}}>
        <div className="page-header-container">
          <div className="row w-100">
            <div className="col-4">
              <i
                onClick={() => {
                  navigate('/appointments');
                }}
                className="fa-solid fa-arrow-left ft-2"
              ></i>
            </div>
            <div className="col-4 text-center">
              <img src={require("../../logo.jpeg")} alt="" height={"50px"} />
            </div>
            <div className="col-4" style={{ position: "relative" }}>
              <span
                style={{ position: "absolute", bottom: "22px" }}
                className="page-title-chat page-title"
              >
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const ShowLoaderScreen = ({enableButton, setShow}) => {
  return(<div className="h-90 d-flex">
    <div className="m-auto">
      <p>Therepist didn't join yet. Please wait...</p>
      <button disabled={!enableButton} className="btn btn-primary" onClick={() => setShow(true)}>Join</button>
    </div>
  </div>)
}

