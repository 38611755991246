import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Assessment from "./components/services/assessment/Assessment";
import SignUpWithOTP from "./components/signup/SignUpWithOTP";
import SignUpUserNameUpdate from "./components/signup/SignUpUserNameUpdate";
import SignUpWithEmail from "./components/signup/SignUpWithEmail";
import LoginWelcome from "./components/login/LoginWelcome";
import LoginIsReady from "./components/login/LoginIsReady";
import ThoughtRestructuringToolList from "./components/services/toolbox/thought-restructuring/ThoughtRestructuringToolList";
import ListCognitiveDistortion from "./components/services/toolbox/cognitive-distortion-exer1/List";
import "./App.css";
import "./styles/chat-mobile.css";
import "./styles/style.css";
import Login from "./components/login/Login";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { useDispatch } from "react-redux";
import { pushToast } from "./redux/slices/toastSlice";
import BehaviouralRecords from "./components/services/toolbox/behavioural-activation/BehaviouralRecords";
import ResilienceHomePage from "./components/services/toolbox/resilience-exercise/ResilienceHomePage";
import ReAssessment from "./components/services/toolbox/reassessment/ReAssessment";
import ThreeCSToolList from "./components/services/toolbox/3cs-tool/ThreeCSToolList";
import routes from "./routes";
import UserSettings from "./components/settings/UserSettings";
import ListTools from "./components/services/toolbox/ListTools";
import HomeScreen from "./components/HomeScreen";
import { ProtectedRoute } from "./components/auth/ProtectedRote";

import "./i18n";
import Chatbot from "./components/services/chatbot";
import Subscriptions from "./components/login/subscriptions";
import TestScreen from "./components/TestScreen";
import bookingRoutes from "./routes/booking-route";
import AgoraUI from "./webchat/component/AgoraUI";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pushToast("Hello world", "success"));
  }, []);
  return (
    <Provider store={store}>
      <Suspense fallback={<p>Loading...</p>}>
      <div className="App">
        <ToastContainer autoClose={2000} />
        <Router>
          <Routes>
            <Route
              path="/video-call"
              element={<AgoraUI/>}
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Chatbot />
                </ProtectedRoute>
              }
              exact={true}
            />
            <Route
              path="/toolbox"
              element={
                <ProtectedRoute>
                  <ListTools />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/assesment"
              element={
                <ProtectedRoute>
                  <Assessment />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/signUp"
              element={
                // <ProtectedRoute>
                  <SignUpWithOTP />
                // </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/signup-ready"
              element={
                // <ProtectedRoute>
                  <LoginIsReady />
                // </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/login"
              element={
                // <ProtectedRoute>
                  <Login />
                // </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/signup-name"
              element={
                // <ProtectedRoute>
                  <SignUpUserNameUpdate />
                // </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/signup-email"
              element={
                // <ProtectedRoute>
                  <SignUpWithEmail />
                // </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/login-welcome"
              element={
                <ProtectedRoute>
                  <LoginWelcome />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path="/login-ready"
              element={
                // <ProtectedRoute>
                  <LoginIsReady />
                // </ProtectedRoute>
              }
              exact
            />
            <Route
              path={routes.THOUGHT_RESTRUCTURING_LIST}
              element={
                <ProtectedRoute>
                  <ThoughtRestructuringToolList />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path={routes.THREE_CS_EXERCISE_LIST}
              element={
                <ProtectedRoute>
                  <ThreeCSToolList />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path={routes.COGNITIVE_DISTORTION_LIST}
              element={
                <ProtectedRoute>
                  <ListCognitiveDistortion />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path={routes.BEHAVIOURAL_ACTIVATION_LIST}
              element={
                <ProtectedRoute>
                  <BehaviouralRecords />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path={routes.RESILIENCE_EXERCISE_LIST}
              element={
                <ProtectedRoute>
                  <ResilienceHomePage />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path={routes.RE_ASSESSMENT_CREATE}
              element={
                <ProtectedRoute>
                  <ReAssessment />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path={routes.USER_SETTINGS}
              element={
                <ProtectedRoute>
                  <UserSettings />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path={routes.HOME_SCREEN}
              element={
                <ProtectedRoute>
                  <HomeScreen />
                </ProtectedRoute>
              }
              exact
            />
            <Route
              path={routes.SUBSCRIPTION}
              element= {
                <Subscriptions/>
              }
            />
            {bookingRoutes.map((route, index) => (
                <Route
                  key={`route-${index}`}
                  path={route.path}
                  Component={route.component}
                />
              ))}
          </Routes>
        </Router>
      </div>
      </Suspense>
    </Provider>
  );
};

export default App;
