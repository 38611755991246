import routes from "../routes";

// eslint-disable-next-line import/no-anonymous-default-export
const toolboxList = [
  {
    title: "thought_restructure_title",
    subtitle: "week",
    route: routes.THOUGHT_RESTRUCTURING_LIST,
    icon: "fa-angle-right",
    isDisabled: false,
  },
  {
    title: "threeCS_exercise",
    subtitle: "week",
    route: routes.THREE_CS_EXERCISE_LIST,
    icon: "fa-angle-right",
    isDisabled: false,
  },
  {
    title: "cog_title",
    subtitle: "week",
    route: routes.COGNITIVE_DISTORTION_LIST,
    icon: "fa-angle-right",
    isDisabled: false,
  },
  {
    title: "behavioural_activation",
    subtitle: "week",
    route: routes.BEHAVIOURAL_ACTIVATION_LIST,
    icon: "fa-angle-right",
    isDisabled: false,
  },
  {
    title: "resilience_title",
    subtitle: "week",
    route: routes.RESILIENCE_EXERCISE_LIST,
    icon: "fa-angle-right",
    isDisabled: false,
  },
  {
    title: "assesment",
    subtitle: "week",
    route: routes.RE_ASSESSMENT_CREATE,
    icon: "fa-lock",
    isDisabled: false,
  },
];

export default toolboxList;
