import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const DonutChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const element = chartRef.current;
    const colors = ["#FF5B7A ", "#97d2d399"];
    const boxSize = 700;

    d3.select(element).select("svg").remove(); // Remove the old svg

    // Create new svg
    const svg = d3
      .select(element)
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
      .append("g")
      .attr("transform", `translate(${boxSize / 2}, ${boxSize / 2})`);

    // Increase innerRadius for a wider empty space
    const arcGenerator = d3.arc().innerRadius(250).outerRadius(350);
    const pieGenerator = d3.pie().value((d) => d.value);

    const arcs = svg.selectAll("path")
      .data(pieGenerator(data))
      .enter()
      .append("path")
      .attr("d", arcGenerator)
      .style("fill", (d, i) => colors[i % data.length]);

    // Optionally, you can return a cleanup function if needed
    return () => {
      // Cleanup code, if necessary
    };
  }, [data]); // Dependency array ensures the effect runs only when data changes

  return <div ref={chartRef}></div>;
};

export default DonutChart;
