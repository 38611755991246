import React, { useEffect } from "react";

const SignUpEmail = ({ formData, handleChange, nextStep, prevStep, errors }) => {
  const handleEmailChange = (e) => {
    handleChange(e);
  };

  useEffect(() => {}, []);

  return (
    <>
      <label className="control-label mb-3 mt-5" htmlFor="email">
        Email
      </label>
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          <i className="fa-solid fa-envelope"></i>
        </span>
        <input
          type="text"
          name="email"
          value={formData.email}
          onChange={handleEmailChange}
          className="form-control h-3 input-border-default"
          placeholder="Email"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
        <div className="invalid-feedback d-block">{errors.email}</div>
      </div>
    </>
  );
};

export default SignUpEmail;
