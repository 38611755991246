import React from "react";
import { PhoneNumberMaskedInput } from "../../common/inputs/PhoneNumberMaskedInput";

const LoginPhone = ({ errors, setFieldValue, formData }) => {
  return (
    <>
      <div className="input-group mb-3">
        <PhoneNumberMaskedInput
          isPhoneValid={errors.phone}
          onChange={(phone) => {
            setFieldValue("phone", phone);
          }}
          value={formData.phone}
        />
      </div>
    </>
  );
};

export default LoginPhone;
