import React from "react";

const SignupHeader = () => {
  return (
    <div className="adjust-to-middle">
      <div className="top-section-green">
        <div className="top-section-header top-section-w2">
          <span className="font-600">Sign up</span>
          <span className="font-300"> to a new account</span>
        </div>
      </div>
    </div>
  );
};

export default SignupHeader;
