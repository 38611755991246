import CryptoJS from "crypto-js";
import { parsePhoneNumber } from "react-phone-number-input";
import { database } from "../firebase";
import moment from "moment";

export function validatePhoneNumber(value) {
  const parsedNumber = parsePhoneNumber(`+${value}`);
  if (parsedNumber?.countryCallingCode === "1") {
    return !(value.length < 11);
  } else {
    return Boolean(parsedNumber?.isValid());
  }
}

export const atleastOneFilled = (value) => {
  let filled = false;
  const temp = Object.values(value);
  const keys = Object.keys(value);
  for(let i = 0; i < temp.length; i++) {
    if(Boolean(temp[i]) && keys[i] !== "status") {
      filled = true;
      break;
    }
  }
  return filled;
}

const parseObjectToArray = (data) =>
  data.map((val) => {
    if (val?.entities) {
      val.entities = Object.values(val.entities);
    } 
    if(val?.suggestedActions?.actions) {
      val.suggestedActions.actions = Object.values(val?.suggestedActions?.actions);
    }
    if(val?.channelData?.["webchat:send-status"]) {
      val.channelData["webchat:send-status"] = "sent";
      val.channelData["state"] = "sent";
    } 
    return val;
});

const parseChat = (weekAsesment) => {
  try {
    return parseObjectToArray(JSON.parse(weekAsesment.toJSON()));
  } catch(e) {
    return [];
  }
}

export const getRefUrl = (week, user, attempt) => `chat/${process.env.REACT_APP_FIREBASE_PREFIX}/user_${user.id}${parseInt(attempt) && attempt != "0" ? `/attempt-${attempt}/` : '/'}${week}`;
 
export const fetchExistingChat = async (week, user, attempt) => {
  let data = [];
  if(week === 0) {
    return [];
  } else {
    for(let k = 0; k < Number(attempt) + 1; k++) {
      for(let i = 0; i < 6; i++) {
        const refUrl = getRefUrl(i, user, k);
        const weekAsesment = await database.ref(refUrl).get();
        if(weekAsesment.toJSON()) {
          console.log({attempt: k, week: i})
          data = [...data, ...parseChat(weekAsesment) ?? {}];
        } 
      }
    }
  }
  return data;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const convertObjectKeyToObject = (obj) => {
  return Object.keys(obj).filter((val) => !isNaN(val)).map((temp) => obj[temp]); 
}

export function groupDatesByDay(dateList) {
  const groupedDates = {};

  dateList.forEach(dateString => {
    const date = moment(dateString);
    const formattedDate = date.format('YYYY-MM-DD');

    if (!groupedDates[formattedDate]) {
      groupedDates[formattedDate] = [];
    }

    groupedDates[formattedDate].push(dateString);
  });

  return groupedDates;
}


export function getBaseUrl() {
  return `${window.location.protocol}//${window.location.host}`;
}

const secretPass = process.env.REACT_APP_SECRET_KEY;
export function encrypt(string) {
  return btoa(CryptoJS.AES.encrypt(
    string,
    secretPass
  ).toString());
}

export function decrypt(string) {
  const bytes = CryptoJS.AES.decrypt(atob(string), secretPass);
  return bytes.toString(CryptoJS.enc.Utf8);
}