import { createSlice } from '@reduxjs/toolkit';
import { toast } from "react-toastify";

const initialState = {};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    pushToast: (state, action) => {
      if(action?.payload?.type === "success") {
        toast.success(action?.payload?.message)
      } else {
        toast(action?.payload?.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });
      }
      
    },
  },
});

export const { pushToast } = toastSlice.actions;

export default toastSlice.reducer;
