// userSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  newUser: null,
  firebaseUser: null,
  selectedSlotForBooking: null,
  selectedDoctorForBooking: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setResetUser: (state, action) => {
      state.user = null;
      state.newUser = null;
      state.firebaseUser = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    setSelectedDoctorForBooking: (state, action) => {
      state.selectedDoctorForBooking = action.payload;
    },
    setSelectedSlotForBooking: (state, action) => {
      state.selectedSlotForBooking = action.payload;
    },
    setNewUser: (state, action) => {
      state.newUser = action.payload;
    },
    clearNewUser: (state) => {
      state.newUser = null;
    },
    setCurrentWeek: (state, action) => {
      state.user.currentweek = action.payload;
    },
    setFirebaseUser:(state, action) => {
      state.firebaseUser = action.payload;
    }
  },
});

export const { setSelectedSlotForBooking, setSelectedDoctorForBooking, setUser, clearUser, setNewUser, clearNewUser, setCurrentWeek, setFirebaseUser, setResetUser } = userSlice.actions;

export default userSlice.reducer;
