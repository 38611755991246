import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginWelcome = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState('en'); 

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value); 
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleStartSignUpProcess = () => {
    localStorage.setItem("inputValue", inputValue);
    const value = localStorage.getItem("inputValue");
    console.log(value);
    navigate("/assessment");
  };
  return (
    <div className="adjust-to-middle">
      <div className="nameUpdateConainer login-welcome-wrapper">
        <div className="d-flex justify-content-center">
          <p className="username-label login-welcome-label" style={{"paddingTop" : "140px"}}>Welcome</p>
        </div>
        <div className="d-flex justify-content-center">
          <span className="welcome-description">Are you ready to take the gad-7/phq-9 test?</span>
        </div>
        <div className="d-flex justify-content-center flex-column selectLanguageContainer align-items-center">
      <label htmlFor="languageSelect" className="text-light pt-15">Select Language </label>
      <br></br>
      <select id="languageSelect" className="form-control" value={selectedLanguage} onChange={handleLanguageChange}>
        <option value="en" style={{border: "1px solid red"}}>English</option>
        <option value="hi">Hindi - हिंदी</option>
        {/* Add more language options as needed */}
      </select>
      {/* <p>Selected Language: {selectedLanguage}</p> */}
    </div>
        <div className="d-flex justify-content-center">
          <button
            className="username-button welcome-button"
            onClick={handleStartSignUpProcess}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginWelcome;
