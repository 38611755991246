// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyDElJ7IqXmK_-zkzkkZLIgRG_WCDIWjEPc",
    authDomain: "iwillcare-survey.firebaseapp.com",
    projectId: "iwillcare-survey",
    storageBucket: "iwillcare-survey.appspot.com",
    messagingSenderId: "469450783387",
    appId: "1:469450783387:web:cc9a6c606de1c7d065643e",
    measurementId: "G-QLD9NKTDVM",
    databaseURL: 'https://iwillcare-survey-default-rtdb.asia-southeast1.firebasedatabase.app/'
  };