// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // Common
  loading_text: "लोड हो रहा है",
  this_field_required: "यह फ़ील्ड आवश्यक है",
  enter_valid_phone: "मान्य मोबाइल नंबर दर्ज करें",
  otp_must_six_digit: "ओटीपी 6 अंकों का होना चाहिए",
  submit: "सबमिट करें",
  save_for_later: "सेव करें",
  completed: "पूरित",
  completed_lo: "पूर्ण टास्क",
  tool_added_success: "टूल सफलतापूर्वक दर्ज किया गया है",
  success: "सफलता",
  error: "त्रुटि",
  next: "अगला",
  discard: "मिटा दें",
  something_went_wrong: "कुछ गलत हो गया। बाद में पुन: प्रयास।",
  save_for_later_asking: "क्या आप इसे बाद के लिए सहेजना चाहते हैं?",
  behavioral_activation: "व्यवहारिक सक्रियता",
  // Login Screen
  login_sc_header: "लॉग इन/रजिस्टर करें",
  login_sc_header_part2: "अपने खाते में",
  login_sc_send_otp_button: "वन टाइम पासवर्ड भेजें",
  login_sc_signin_button: "लॉगिन करें",
  login_sc_sending_otp_text: "ओटीपी भेजा जा रहा है",
  login_sc_otp_text: "ओटीपी",
  language: "भाषा चुने ",
  //Home Page
  chat_menu_title: "चैट",
  chat_menu_subtitle: `6 हफ्ते/सप्ताह का संवेदनशील, ट्रेंड चैट बॉट सेशन,
    आपकी सहायता और मानिसक स्वस्थ की मदद के लिए `,
  toolbox_menu_title: "टूल-बॉक्स",
  toolbox_menu_subtitle: "एक्सरसाइज और टूल जो आप लगातार अभ्यास करेंगे/करेंगी",
  depression_level: "मध्य अवसाद",
  //chat header
  chat_heading: "चैट",
  toolbox_heading: "टूल-बॉक्स",
  //EXERCISES:
  //emotion
  section_1_emotion: "इमोशंस (भावनाओं)",
  section_2_catch_thought:" कैच (भावना को पहचानें) - भावनाओं से पहले के विचार को समझें",
  section_3_check_accuraccy:
    "चेक (ध्यान दें) - विचार करें कि क्या ये एक दम सही हैं?",
  section_4_provide_more_accuraccy:
    "चेंज (बदलाव करें) - इस विचार को  एक संतुलित/सही  (बैलेंस्ड) विचार में बदलें",
  //Cognitive Distortions:
  cog_title: "सोच के प्रकार",
  current_situation: "आप की अभी की स्तिथि/सिचुएशन क्या है?",
  negative_thought: "अपने नेगेटिव/नकारात्मक विचार को यहाँ लिखें",
  clue_for_answer:
    "कृपया कॉग्निटिव विचार/सोच के प्रकार/विकृति का चयन करें जिसे आपने पिछले प्रश्न का उत्तर देते समय पहचाना था",
  overgeneralization: "ओवर-जेनेरलाइज़ेशन (अति-सामान्यीकरण)",
  overgeneralization_elaboration:
    "एक-दो घटनाओं के आधार पर व्यापक/हमेशा के लिए  निष्कर्ष निकालना",
  magnification: "मैग्निफिकेशन (अतिशयोक्ति)",
  magnification_elaboration:
    "किसी एक घटना के महत्व या परिणामों को बढ़ा-चढ़ाकर सोचना/बताना",
  emotional_reasoning: "इमोशनल रीजनिंग (भावनात्मक तर्क)",
  emotional_reasoning_elaboration:
    "विश्वास है कि भावनाएं वास्तविकता को दिखाती/दर्शाती हैं",
  personalization: "पर्सनलाइज़ेशन/ब्लैम (निजीकरण/दोष) ",
  personalization_elaboration:
    "अपने आप को जिम्मेदार ठहराना / दूसरों को किसी ऐसी चीज के लिए दोष देना जो पूरी तरह से आपके/उनके  नियंत्रण में नहीं है",
  should_statement: "शुड  स्टेटमेंट्स (होना ही चाहिए)",
  should_statement_elaboration:
    "इस बात पर ध्यान केंद्रित करना कि चीजें आपकी अपेक्षाओं/ इच्छाओं पर आधारित होनी चाहिए",
  catastrophizing: "कैटोस्ट्रॉफाईजिंग (ख़राब होने की कल्पना)",
  catastrophizing_elaboration: "हमेशा सबसे खराब स्थिति की उम्मीद करना",
  fortune_telling: "फॉर्च्यून टेलिंग",
  fortune_telling_elaboration:
    "कम सबूतों के साथ भविष्यवाणियां / निष्कर्ष निकालना",
  all_or_nothing: "आल और नथिंग (सभी या कुछ भी नहीं की सोच) ",
  all_or_nothing_elaboration: "चरम सीमाओं में सोचना: या तो मैं सफल हूँ या विफल",
  labelling: "लेबलिंग (धारणा बनाना)",
  labelling_elaboration:
    'एक अनुभव के आधार पर खुद को या किसी और के लिए निर्णय कर लेना  या "लेबल" लगाना',
  mind_reading: "माइंड रीडिंग (दिमाग को पढ़ना)",
  mind_reading_elaboration:
    "यह मानना कि आप जानते हैं कि कोई और क्या सोच रहा है",
  cog_more_accurate_thought: "विपरीत/वैकल्पिक या अधिक सटीक विचार",
  after_feeling:
    "अपने संज्ञानात्मक-विकृति (सोचने की गड़बड़ी) की पहचान करने के बाद आप कैसा महसूस कर रहे हैं?",
  only_tow_selection_error: "आप दो से अधिक का चयन नहीं कर सकते.",
  //Behavioral Activation:
  task_title: "कार्य शीर्षक",
  task_description: "कार्य विवरण",
  new_reminder: "+ नया रिमाइंडर",
  add_reminder_here: "आप का रिमाइंडर यहाँ ऐड किया जायेगा",
  task_title_ex: "टास्क/कार्य का नाम",
  task_description_ex: "टास्क/कार्य का विवरण",
  choose_active_type: "कार्य का प्रकार चुनें",
  self: "स्वयं के लिये",
  work: "काम",
  social: "सोशल",
  other: "अन्य",
  time: "समय",
  date: "तारीख़",
  schedule: "स्केड्यूल / तय करें",
  type: "प्रकार",
  mark_as_done:
    "बॉक्स पर टिक करके पूरा चिह्नित करें - ध्यान रखें कि यह आपके रिमाइंडर/अनुस्मारक को हटा देगी",
  //Resilience Exercise:
  question: "प्रश्न",
  resilience_title: "रिजिलयनस ( अपनी शक्ति से कठिन परिस्थितियों का सामना करना)",
  recent_example: "रिजिलंयस के एक उदाहरण को याद करें",
  recent_example_elaboration:
    "हाल ही में एक समय के बारे में सोचें जब आप एक चुनौती को पार कर पाए। शायद आपने खुद को किसी बुरी परिस्थिति में पाया, या काम पर कुछ नकारात्मक प्रतिक्रिया प्राप्त की, या किसी मित्र या परिवार के सदस्य के साथ बहस हुई। नीचे दिए गए बाक्स में उस कठिन समय या स्थिति का संक्षेप में वर्णन करें।",
  identify_support: "सहायक और आपका साथ देने वाले लोगों की पहचान करें",
  identify_support_elaboration:
    " आपके जीवन में कौन से 'सहायक लोग' आपको स खड़े रखते हैं जब नीचे गिरना आसान होता? उदाहरण के लिए, क्या आपने किसी पुराने दोस्त को बुलाया, या साथ देतें है, यह कोई भी हो सकते हैं जैसे कोई मेंटर, आपके जीवन साथी, या शायद माता-पिता या दादा-दादी, जिन्होंने इस परिस्थिति में आपको प्रोत्साहन दिया। लिखें कि आपने समर्थन के लिए किसपर विश्वास किया",
  identify_strategies: " रणनीतियों/ योजनाओं की पहचान करें",
  identify_strategies_elaboration:
    "कठिनाई के जवाब में दिखाई देने वाले किसी भी नकारात्मक विचारों और भावनाओं से निपटने में मदद करने के लिए आपने किन 'रणनीतियों' का उपयोग किया? उदाहरण के लिए, क्या आपने ध्यान लगाया, या एक पलैन बनाया और तनाव कम करने के लिए क्या-क्या कार्य किए उन्हें लिखें",
  identify_sagacity: "दूरदर्शिता की पहचान करें ",
  identify_sagacity_elaboration:
    "किस 'दूरदर्शिता' ने आपको इस कठिनाई से वापस आने में मदद की? दूरदर्शिता वह ज्ञान और समझदारी है जिसे आप धारण करते हैं। यह  अनुभव से,  उपन्यास, कविता, आध्यात्मिक लेखन, प्रसिद्ध लोगों के संघर्ष और विजय के बारे में जानकारी से, किसी के दादा-दादी की कहावतें, या अपने स्वयं के निर्णयों से और सीखने से आ सकती है। नीचे अपनी दूरदर्शिता लिखें ",
  identify_behaviour:
    " समाधान और सही दिशा में ले जाने वाले मार्ग और कर्मों की पहचान करें",
  identify_behaviour_elaboration:
    "समस्या से सक्रिय रूप से निपटने में आपकी सहायता करने के लिए आपने कौन से समाधान की और ले जाने वाले कार्य किये, किन चीजों को अपनाया? उदाहरण के लिए, क्या आपने समस्या को हल किया, या नई जानकारी की तलाश की, या आगे की योजना बनाई, या बातचीत की, या अपनी राय दी और दूसरों से मदद मांगी। उन महत्वपूर्ण कार्यों को लिखें",
  //thought restructuring
  thought_restructure_title: "विचार-संतुलन एक्सरसाइज",
  negative_label: "निगेटिव",
  neutral_label: "निष्पक्ष",
  //list tools
  assesment: "असेसमेंट",
  behavioural_activation : "सकारात्मक कार्यों के बदलाव",
  threeCS_exercise: "3-सीज़ एक्सरसाइज",
  add_entry: "नई एंट्री करे ",
  list_title: "3सीअस्स लिस्ट ",
  attempts: "प्रयास",
  week: "सप्ताह",
  partially: "टास्क पूरा करें",
  //Settings
  settings: "सेटिंग्स",
  user_name: "यूजर-नेम",
  dark_mode: "डार्क मोड",
  about_us: "हमारे बारे में जाने ",
  subscriptions: "सब्सक्रिप्शन",
  logout: "लॉगआउट",
  // subscription page
  monthly: "मासिक",
  subscription_plan : "सब्सक्रिप्शन प्लान",
  yearly : "सालाना",
  not_pay_anything : "अभी आपको कुछ पेमेंट नहीं करना है !!",
  full_access_msg : "आपको सभी फीचरस् का पूरा एक्सेस है,अनलिमिटेड एक्सेस !!",
  proceed : "शुरू करे!",
  what_would_you: "हम आपको किस नाम से बुलाएँ?",
  your_name: "आपका नाम",
  continue: "आगे बढ़ें",
  moderate: "मॉडरेट",
  negligible: "नेग्लीजेंबल",
  mild: "माइल्ड", 
  severe: "सीवियर",
  depression: "डिप्रेशन", 
  anxiety: "एंग्जायटी",
  booked_h1: "बुक की गयी अपॉइंटमेंट",
  booked_btn: "अपॉइंटमेंट पर जाएं",
  book_now_h1: "कॉउन्सेलर के साथ सेशन बुक करें",
  book_now_h2: "",
  book_now_t: "",
  book_now_btn: " बुक करें",
  Recommended: "रेकमेंडेड",
  book_session: "बुक सेशन",
  appointment_or_booking: "अपॉइंटमेंट और बुकिंग",
  ongoing: "आने वाली",
  previous: "पिछली",
  appointment_going_on: "अभी की अपॉइंटमेंट पूरी होने के बाद आप अगली अपॉइंटमेंट बुक करिये"
};
