// eslint-disable-next-line import/no-anonymous-default-export
export default {
    APPOINTMENT: "/appointments",
    HOME_ROUTE: "/",
    ASSESMENT: "/assesment",
    SIGNUP: "/signup",
    SIGNUP_EMAIL: "/signup-email",
    LOGIN: "/login",
    SIGNUP_NAME: "/signup-name",
    
    THOUGHT_RESTRUCTURING_LIST: '/thought-restructuring',
    THREE_CS_EXERCISE_LIST: '/three-cs',
    COGNITIVE_DISTORTION_LIST: '/cognitive-distortion',
    BEHAVIOURAL_ACTIVATION_LIST: '/behavioural-activation',
    RESILIENCE_EXERCISE_LIST: '/resilience-exercise',
    RE_ASSESSMENT_CREATE: '/re-assessment',

    SIGNUP_READY: '/signup-ready',

    USER_SETTINGS: "/user-settings",

    HOME_SCREEN: "/home-screen",

    SUBSCRIPTION: "/subscription",
    BOOK_NOW: '/book-now',
    DOCTORS_LIST: "/doctors-list",
    VIDEO_CALL: "/video-call"
}