import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderFunction from "../common/HeaderFunctions";
import { useTranslation } from "react-i18next";
import routes from "../../routes";

const ChatHeader = ({ path }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const handleNevigation = (newPath) => {
    navigate(newPath);
  };
  return (
    <>
      <div className="top-header">
        <div className="page-header-container">
          <div>
            <i
              onClick={() => {
                navigate(routes.HOME_SCREEN);
              }}
              className="fa-solid fa-home ft-2"
            ></i>
          </div>
          <div className="text-center" style={{justifySelf: "center", position: 'relative'}}>
              <img src={require("../../logo.jpeg")} alt="" height={"50px"} />
            </div>
          <HeaderFunction />
        </div>
        <div className="toggle-button-effect">
          <button
            id="left-togl-btn"
            type="button"
            onClick={() => handleNevigation("/")}
            className={`btn  btn-purple mt-3 h-3 toggle-active-btn-left ${
              path === "chatbot" ? "toggle-active-left" : ""
            }`}
          >
            {t("chat_heading")}
          </button>
          <button
            type="button"
            onClick={() => handleNevigation("/toolbox")}
            className={`btn  btn-purple mt-3 h-3 toggle-active-btn-right ${
              path !== "chatbot" || pathname !== "/" ? "bg-purple" : ""
            } `}
          >
            {t("toolbox_heading")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
