import Modal from "../../Modal";

export default function ToolPreviewModal({
  isOpen,
  onCloseClickHandler,
  children,
  closeBtnStyle = {},
  showCloseButton = true,
}) {
  return (
    <Modal isOpen={isOpen}>
      <div className="d-flex justify-content-center">
        <div className="w-357">
          {showCloseButton && (<button
            type="button"
            style={closeBtnStyle}
            className="close modal-close-btn-updated d-flex justify-content-end w-100"
            onClick={() => {
              onCloseClickHandler();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>)}
        </div>
      </div>
      {children}
    </Modal>
  );
}
