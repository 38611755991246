import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

// Custom styles for the Switch
const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 30,
  borderRadius: 15,
  padding: 5,
  "& .MuiSwitch-thumb": {
    boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 15,
    backgroundColor: theme.palette.mode === "dark" ? "#888" : "#ccc",
  },
  "&.Mui-checked .MuiSwitch-thumb": {
    transform: "translateX(30px)",
  },
}));

const ModeSwitch = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleModeChange = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <StyledSwitch
      checked={isDarkMode}
      onChange={handleModeChange}
      color="default"
    />
  );
};

export default ModeSwitch;
