import React, { useState } from "react";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { pushToast } from "../../../../redux/slices/toastSlice";
import api from "../../../../api";
import { useFormik } from "formik";
import modals from "../../../../modals";
import toolAction from "../../../../constant/tool-action";
import { useTranslation } from "react-i18next";
import { atleastOneFilled } from "../../../../utils";

const AddResilienceEntry = React.forwardRef((props, ref) => {
  const [step, setStep] = useState(1);
  const [isSelected, setIsSelected] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const { user } = useSelector((state) => state?.userReducer);
  const [loading, setLoading] = React.useState(false);
  const {t} = useTranslation();
  const defaultValue = {
    answer_one: "",
    answer_two: "",
    answer_three: "",
    answer_four: "",
    answer_five: "",
  };

  const schema = yup.object().shape({
    answer_one: yup.string().required(),
    answer_two: yup.string().required(),
    answer_three: yup.string().required(),
    answer_four: yup.string().required(),
    answer_five: yup.string().required(),
  });

  const dispatch = useDispatch();

  const showToast = (message, type) => {
    dispatch(
      pushToast({
        message,
        type,
      })
    );
  };

  const OnSubmitHandler = async (values) => {
    setLoading(true);
    const response = api.storeResilenceExerciseTool({
      ...values,
      user_device_token: user?.user_device_token ?? user.device_token,
      status: values.status ?? "COMPLETED",
      bot_session_id: props?.sessionId,
    });
    setLoading(false);

    if (response) {
      props?.FetchTool && props.FetchTool();
      // showToast(t("tool_added_success"), t("success"));
      props?.closeModal(modals.MODAL_STATUS_ADD_RESILIENCE);
      return true;
    } else {
      showToast(t("something_went_wrong"), t("error"));
      return false;
    }
  };

  const { handleChange, values, errors, submitForm, validateForm } = useFormik({
    initialValues: props.selectedItem ?? defaultValue,
    onSubmit: OnSubmitHandler,
    validationSchema: schema,
    validateOnMount: true,
  });

  const saveForLater = () => {
    let payload = { ...values };
    const valid = atleastOneFilled(payload);
    if(valid) {
      payload.status = "PARTIAL_COMPLETED";
      OnSubmitHandler(payload);
    } else {
      validateForm();
      setIsSelected(true);
    }
  };

  React.useImperativeHandle(ref, () => ({
    saveForLater,
  }));

  const nextStep = () => {
    setStep((prevStep) => {
      const updatedStep = prevStep + 1;
      return updatedStep;
    });
  };

  const prevStep = () => {
    setStep((prevStep) => {
      const updatedStep = prevStep - 1;
      return updatedStep;
    });
  };

  const handleAction = () => {
    if (step < 5) {
      let disabled = true;
      setIsSelected(true);
      if (isSelected) {
        if (step === 1) {
          if(errors.answer_one) {
          setIsNextDisabled(true);
          
        } else {
          setIsNextDisabled(false);
          disabled = false
        }
      }
        if (step === 2) { if(errors.answer_two) {
          setIsNextDisabled(true);
        } else {
          setIsNextDisabled(false);
          disabled = false
        }
      }
        if (step === 3) { if(errors.answer_three) {
          setIsNextDisabled(true);
        } else {
          setIsNextDisabled(false);
          disabled = false
        }
      }
        if (step === 4) { if(errors.answer_four) {
          setIsNextDisabled(true);
        } else {
          setIsNextDisabled(false);
          disabled = false
        }
      }
    }
      if (!disabled) {
        nextStep();
      }
    } else {
      if (step === 5) { if(errors.answer_five) {
        setIsNextDisabled(true);
      } else {
        submitForm();
      }
      
    }
  }
  };

  return (
    <>
      <div className="modal-body modal-body-scr-4">
        <div
          className="modal-quest m-center mt-3"
          style={{ paddingBottom: "20px", borderRadius: "24px" }}
        >
          <div className="d-flex justify-content-start align-items-center">
            {step >= 2 && step <= 5 && (
              <i
                onClick={() => {
                  prevStep();
                }}
                className="cursor-pointer fa-sharp fa-solid fa-arrow-left color-purple toolbox-navigate-prev"
              ></i>
            )}
            <div className="popup-header pl--7" style={{ position: "inherit" }}>
              {t("question")} {step}/5
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-3">
            <span
              className={`progress-bar progress-radious-left ${
                step >= 1 ? "progress-active" : "progress-inactive"
              }`}
            ></span>
            <span
              className={`progress-bar ${
                step >= 2 ? "progress-active" : "progress-inactive"
              }`}
            ></span>
            <span
              className={`progress-bar ${
                step >= 3 ? "progress-active" : "progress-inactive"
              }`}
            ></span>
            <span
              className={`progress-bar ${
                step >= 4 ? "progress-active" : "progress-inactive"
              }`}
            ></span>
            <span
              className={`progress-bar progress-radious-right ${
                step === 5 ? "progress-active" : "progress-inactive"
              }`}
            ></span>
          </div>

          <div className="p--7 mt-1">
            {step === 1 && (
              <>
                <span className="chat-popup-inner-heading">
                  <span className="text-danger">*</span> {t("recent_example")} -
                </span>
                <span className="chat-popup-inner-text">
                  {t("recent_example_elaboration")}
                </span>
                <div className="text-area-popup text-center">
                  <textarea
                    className="text-area-itself w-100"
                    name={"answer_one"}
                    value={values.answer_one}
                    disabled={props.mode === toolAction.PREVIEW}
                    error={"This is a required field"}
                    onChange={handleChange}
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                {errors.answer_one && isSelected && (
                  <div
                    className="invalid-feedback d-block"
                    style={{ marginTop: "1px", marginLeft: "14px" }}
                  >
                   {t("this_field_required")}
                  </div>
                )}
              </>
            )}
            {step === 2 && (
              <>
                <span className="chat-popup-inner-heading">
                  <span className="text-danger">*</span> {t("identify_support")} -
                </span>
                <span className="chat-popup-inner-text">
                  {t("identify_support_elaboration")}
                </span>
                <div className="text-area-popup text-center">
                  <textarea
                    className="text-area-itself w-100"
                    name={"answer_two"}
                    value={values.answer_two}
                    disabled={props.mode === toolAction.PREVIEW}
                    error={"This is a required field"}
                    onChange={handleChange}
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                {errors.answer_two && isSelected && (
                  <div
                    className="invalid-feedback d-block"
                    style={{ marginTop: "1px", marginLeft: "14px" }}
                  >
                   {t("this_field_required")}
                  </div>
                )}
              </>
            )}
            {step === 3 && (
              <>
                <span className="chat-popup-inner-heading">
                  <span className="text-danger">*</span> {t("identify_strategies")} -
                </span>
                <span className="chat-popup-inner-text">
                  {t("identify_strategies_elaboration")}
                </span>
                <div className="text-area-popup text-center">
                  <textarea
                    className="text-area-itself w-100"
                    name={"answer_three"}
                    value={values.answer_three}
                    disabled={props.mode === toolAction.PREVIEW}
                    error={"This is a required field"}
                    onChange={handleChange}
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                {errors.answer_three && isSelected && (
                  <div
                    className="invalid-feedback d-block"
                    style={{ marginTop: "1px", marginLeft: "14px" }}
                  >
                   {t("this_field_required")}
                  </div>
                )}
              </>
            )}
            {step === 4 && (
              <>
                <span className="chat-popup-inner-heading">
                  <span className="text-danger">*</span> {t("identify_sagacity")} -
                </span>
                <span className="chat-popup-inner-text">
                  {t("identify_sagacity_elaboration")}
                </span>
                <div className="text-area-popup text-center">
                  <textarea
                    className="text-area-itself w-100"
                    name={"answer_four"}
                    value={values.answer_four}
                    disabled={props.mode === toolAction.PREVIEW}
                    error={"This is a required field"}
                    onChange={handleChange}
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                {errors.answer_four && isSelected && (
                  <div
                    className="invalid-feedback d-block"
                    style={{ marginTop: "1px", marginLeft: "14px" }}
                  >
                   {t("this_field_required")}
                  </div>
                )}
              </>
            )}
            {step === 5 && (
              <>
                <span className="chat-popup-inner-heading">
                  <span className="text-danger">*</span> {t("identify_behaviour")} -
                </span>
                <span className="chat-popup-inner-text">
                  {t("identify_behaviour_elaboration")}
                </span>
                <div className="text-area-popup text-center">
                  <textarea
                    className="text-area-itself w-100"
                    name={"answer_five"}
                    value={values.answer_five}
                    disabled={props.mode === toolAction.PREVIEW}
                    error={"This is a required field"}
                    onChange={handleChange}
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                {errors.answer_five && isSelected && (
                  <div
                    className="invalid-feedback d-block"
                    style={{ marginTop: "1px", marginLeft: "14px" }}
                  >
                   {t("this_field_required")}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="d-flex justify-content-around align-items-center">
            {loading ? (
              <p>{t("loading_text")}</p>
            ) : (
              <>
                <button
                  id="left-togl-btn"
                  type="button"
                  className="btn btn-primary btn-purple mt-3 h-3"
                  style={{ right: 0, width: "48%" }}
                  onClick={() => handleAction()}
                  // disabled={isNextDisabled}
                  // disabled={isNextDisabled}
                  // disabled={step === 5}
                >
                  {t("next")}
                </button>
                <button
                  type="button"
                  style={{ width: "48%" }}
                  className="btn btn-primary btn-purple mt-3 h-3"
                  onClick={async () => {
                    saveForLater();
                  }}
                >
                  {t("save_for_later")}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
});
export default AddResilienceEntry;
