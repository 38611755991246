import React from "react";
import { useNavigate } from "react-router-dom";
import toolboxList from "../../../constant/toolbox-list";
import ChatHeader from "../ChatHeader";
import { useTranslation } from "react-i18next";

const ListTools = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  return (
    <>
      <ChatHeader path={"toolbox"} />
      <div className="modal-header-wrapper w-95">
        {toolboxList.map((val, index) => (
          <div className={`m-center w-98 ${index !== 0 && "mt-2"}`} key={`list-item-${index}`}>
            <button
              disabled={val.isDisabled}
              className={`d-flex justify-content-between align-items-center toolbox-list-element toolbox-border-light w-100 bg-transparent`}
              onClick={() => navigate(val.route)}
            >
              {/* <div className="list-element ">02</div> */}
              <div className="align-items-start d-flex flex-column justify-content-center text-left">
                <span className="inner-top-element mt-3">{t(val.title)}</span>
                <span className="week-sub-heading mt-1 mb-3">
                  {t(val.subtitle)} {index+1}
                </span>
              </div>
              <div>
                <i className={`fa-solid ${val.icon} toolbox-right-icon`}></i>
              </div>
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default ListTools;
