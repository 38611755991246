import { lazy } from "react";

const bookingRoutes = [
  {
    path: "/appointments",
    component: lazy(() => import("../booking-pages/ongoing/page"))
  },
  {
    path: "/payment",
    component: lazy(() => import("../booking-pages/payment/page"))
  },
  {
    path: "/doctor-profile",
    component: lazy(() => import("../booking-pages/doctor-profile/page"))
  },
  {
    path: "/doctors-list",
    component: lazy(() => import("../booking-pages/doctors-list/page"))
  },
  {
    path: "/saved-doctors",
    component: lazy(() => import("../booking-pages/saved-doctors/page"))
  },
  {
    path: "/book-now/:doctorId",
    component: lazy(() => import("../booking-pages/book-doctor/book-now/page"))
  },
  {
    path: "/booking-details",
    component: lazy(() => import("../booking-pages/book-doctor/booking-details/page"))
  },
  {
    path: "/checkout",
    component: lazy(() => import("../booking-pages/book-doctor/checkout/page"))
  },
  {
    path: "/payment",
    component: lazy(() => import("../booking-pages/book-doctor/payment-gateway/page"))
  },
  {
    path: "/booking-completed",
    component: lazy(() => import("../booking-pages/book-doctor/booking-completed/page"))
  },
  {
    path: "/ongoing",
    component: lazy(() => import("../booking-pages/book-doctor/ongoing/page"))
  }, 
  {
    path: "/doctor-profile/:id",
    component: lazy(() => import("../components/doctor-profile/page"))
  }
];

export default bookingRoutes;
