import React from "react";
import { PhoneNumberMaskedInput } from "../../common/inputs/PhoneNumberMaskedInput";

const SignupPhone = ({ formData, handleChange, errors, setFieldValue }) => {
  return (
    <>
      <label className="control-label mb-3 mt-5" htmlFor="phoneNumber">
        Phone Number
      </label>
      <div className="input-group mb-3">
        <PhoneNumberMaskedInput
            isPhoneValid={errors.phone}
            onChange={(phone) => {
              setFieldValue("phone", phone);
            }}
            value={formData.phone}
          />
        <div className="invalid-feedback d-block">{errors.phone}</div>
      </div>
    </>
  );
};

export default SignupPhone;
