import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../routes";

const LoginIsReady = () => {
  const navigate = useNavigate();
  const handleStartSignUpProcess = () => {
   navigate(routes.ASSESMENT)
  };
  return (
    <div className="adjust-to-middle">
      <div className="nameUpdateConainer login-welcome-wrapper">
        <div className="d-flex justify-content-center">
          <p className="username-label login-welcome-label">You are ready!</p>
        </div>
        <div className="d-flex justify-content-center">
          <span className="welcome-description">Your test is completed and all set up to continue</span>
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="username-button welcome-button"
            onClick={handleStartSignUpProcess}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginIsReady;
