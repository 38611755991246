import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../routes";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { clearUser } from "../../redux/slices/userSlice";

const HeaderFunction = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen();
  };

  const handleLogout = () => {
    // Handle logout logic here
    console.log("User logged out");
  };
  const navigate = useNavigate();

  const onLogoutClickHandler = () => {
    dispatch(clearUser());
    navigate(routes.LOGIN);
  };

  const onSettingsClickHandler = () => {
    navigate(routes.USER_SETTINGS)
  };

  return (
    <div className="d-flex align-items-center">
      {/* <i className="fa-solid fa-arrow-up-a-z caps mx-2"></i> */}
      {/* <i className="fa-solid fa-arrow-down-a-z caps mx-2"></i> */}

      <div className="dropdown-container">
        <div className="gear-icon"
        //  onClick={handleToggleDropdown}
         >
          {/* <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => <Tooltip {...props}>Logout</Tooltip>}
            placement="bottom"
          > */}
            <i
              onClick={onSettingsClickHandler}
              className="fa-solid fa-align-justify gear"
            ></i>
          {/* </OverlayTrigger> */}
        </div>
        {/* {isDropdownOpen && (
          <div className="dropdown-menu">
            <ul>
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default HeaderFunction;
