import React, { useState, useEffect } from "react";
import SignupFooter from "./SignupFooter";
import SignupHeader from "./SignupHeader";
import SignupPhone from "./SignUpPhone";
import SignUpOTP from "./SignUpOTP";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { auth, firebase } from "../../firebase";
import api from "../../api";
import { setUser } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import routes from "../../routes";
import { validatePhoneNumber } from "../../utils";
import { parsePhoneNumber } from "react-phone-number-input";
import { pushToast } from "../../redux/slices/toastSlice";

const initialValues = {
  phone: "",
  otp: "",
};

const schema = yup.object({
  phone: yup
    .string()
    .required("Phone number is required")
    .test(
      "phone-test",
      "Enter valid mobile number",
      (val) => validatePhoneNumber(val)
    ),
  otp: yup
    .string()
    .length(6, "OTP must be 6 digits")
    .required("OTP is required"),
});

const SignUpWithOTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state?.userReducer);

  const [loader, setLoader] = React.useState(false);
  const [final, setFinal] = React.useState(null);
  const [step, setStep] = useState(1);

  const showToast = (message, type) => {
    dispatch(pushToast({
       message,
       type
    }))
  };

  const submitHandler = async (e) => {
    setLoader(true);
    try {
      const confirm = await final.confirm(values.otp);
      if (confirm) {
        const parsedNumber = parsePhoneNumber(`+${values.phone}`);
        const payload = {
          phone: parsedNumber.nationalNumber,
          name: user?.name,
          device_token: moment().unix(),
          country_code: parsedNumber?.countryCallingCode || "",
        };
        const saveUser = await api.signUp(payload);
        if (saveUser) {
          // showToast("User created successfully.", "error");
          const response = await saveUser.json();
          dispatch(setUser({ ...user, ...response.data }));
          navigate(routes.SIGNUP_READY);
        } else {
          // showToast("Something went wrong. Try again later.", "error");
          setLoader(false);
        }
      } else {
        // showToast("Something went wrong. Try again later.", "error");
        setLoader(false);
      }
    } catch (e) {
      if (e?.code === "auth/invalid-verification-code") {
        // showToast("Incorrent OTP.", "error");
      } else {
        // showToast("Something went wrong. Try again later.", "error");
      }

      setLoader(false);
    }
  };

  const { handleChange, values, errors, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      onSubmit: submitHandler,
      validationSchema: schema,
    });

  const nextStep = () => {
    setStep((prevStep) => {
      const updatedStep = prevStep + 1;
      return updatedStep;
    });
  };

  const prevStep = () => {
    setStep((prevStep) => {
      const updatedStep = prevStep - 1;
      return updatedStep;
    });
  };

  const onPhoneNumberSetHandler = () => {
    setLoader(true);
    const mynumber = values.phone;
    if (mynumber === "" || mynumber.length < 10) return;
    const verify = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log("response", response);
      },
    });

    auth
      .signInWithPhoneNumber("+" + mynumber, verify)
      .then((result) => {
        // showToast("OTP Sent successfully.", "success");
        setFinal(result);
        setLoader(false);
        nextStep();
      })
      .catch(() => {
        // showToast("Something went wrong. Try again later.", "error");
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!user?.name) {
      navigate(routes.SIGNUP_NAME);
    }
  }, []);

  return (
    <div className="">
      <SignupHeader />
      <div id="recaptcha-container"></div>
      <div className="form-group bottom-form">
        {step === 1 && (
          <SignupPhone
            setFieldValue={setFieldValue}
            formData={values}
            errors={errors}
            handleChange={handleChange}
            nextStep={nextStep}
          />
        )}
        {step === 2 && (
          <SignUpOTP
            formData={values}
            errors={errors}
            handleChange={handleChange}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )}
        {!loader ? (
          <button
            type="submit"
            className="btn  btn-green h-3 fw-200"
            onClick={(e) => {
              handleSubmit(e);
              if (step === 1 && !errors.phone && Boolean(values.phone)) {
                onPhoneNumberSetHandler();
              }
            }}
          >
            {step === 1 ? "Send One Time Password" : "Sign Up"}
          </button>
        ) : (
          <p>Loading...</p>
        )}
        <SignupFooter />
      </div>
    </div>
  );
};

export default SignUpWithOTP;
