import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import { createStore, createStyleSet } from "botframework-webchat";
import simpleUpdateIn from "simple-update-in";
import { current, produce } from 'immer';
import WebChat from "./WebChat";
import "../../../fabric-icons-inline.css";
import "../../../styles/MinimizableWebChat.css";
import Modal from "../../Modal";
import { useSelector } from "react-redux";
import AddNewThoughtRestructuring from "../toolbox/thought-restructuring/AddNewThoughtRestructuring";
import AddNew from "../toolbox/cognitive-distortion-exer1/AddNew";
import ThreeCSToolPreview from "../toolbox/3cs-tool/ThreeCSToolPreview";
import AddResilienceEntry from "../toolbox/resilience-exercise/AddResilienceEntry";
import modals from "../../../modals";
import ToolPreviewModal from "../../common/tool-preview-modal/ToolPreviewModal";
import toolAction from "../../../constant/tool-action";
import ChatHeader from "../ChatHeader";
import { useDispatch } from "react-redux";
import { setTempStore, setToken, setWeekAssesment } from "../../../redux/slices/chatSlice";
import { useTranslation } from "react-i18next";
import AddNewRecord from "../toolbox/behavioural-activation/AddNewRecord";
import ReAssessment from "../toolbox/reassessment/ReAssessment";
import { database } from "../../../firebase";
import api from "../../../api";
import { setUserInRedux } from "../user/userService";
import { getRefUrl } from "../../../utils";
import { getWebChatJoin } from "./events";
import moment from "moment";

let currentWeekAvail = 0;
let currentAttempt = "0";
const logWeekRef = [];
const MinimizableWebChat = ({ adtoken, username, chatHistory, isFetched }) => {
  const addNewThoughtRestructuringRef = useRef();
  const addThreeCSRef = useRef();
  const cognitiveDistortionRef = useRef();
  const addResilienceRef = useRef();
  const behavioralRefordRef = useRef();
  const conversationIdRef = useRef();
  const chatRef = useRef(chatHistory?.activities ?? []);

  const mode = toolAction.CREATE;
  const selectedItem = undefined;

  const { user } = useSelector((state) => state?.userReducer);

  useEffect(() => {
    currentWeekAvail = user.currentweek
    currentAttempt = user.psybot_attempt
  }, [])
 

  const [loading, setLoading] = React.useState(false);
  const [sessionId, setSessionId] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  
  const { t } = useTranslation();

  const { token, conversationId } = useSelector((state) => state?.chatReducer);
  
  const logDataIntoWeek = async (week, attempt) => {
    const node = await database.ref(getRefUrl(week, user, attempt)).get();
    const dataToAddInWeek = Object.values(chatRef.current).filter(
      (val) => (
        val?.channelData?.week == week && val?.channelData?.currentAttempt == attempt
      ) || (val?.value?.attempt === attempt && val?.value?.week === week));
    if(!node.toJSON() && dataToAddInWeek.length !== 0) {
      // Set to the week
      database.ref(getRefUrl(week, user, attempt)).set(JSON.stringify(dataToAddInWeek));
      const payload = {
        device_token: user.device_token,
        currentweek: week + 1,
        conversation_id: conversationIdRef.current || conversationId,
        attempt: attempt,
      };
      await api.postUserCurrentWeek(payload);
      const user_ = await setUserInRedux(user, dispatch);
      currentAttempt = user_.psybot_attempt;
      return true;
    } else {
      return false;
    }
  }

  const { assesmentWeekStatus } = user;
  const isEligibleForNextWeek = useMemo(() => {
    const lastAssesment = Array.isArray(assesmentWeekStatus) ? (assesmentWeekStatus.length === 1 ? assesmentWeekStatus[0] : true) : false;
    if(String(typeof lastAssesment) !== "boolean") {
      const now = moment();
      const lastDateTime = moment(lastAssesment.date_time);
      return now.diff(lastDateTime, 'minutes') > 10;
    }
  }, [assesmentWeekStatus]);

  const typingEvents = ["WEB_CHAT/STOP_DICTATE", "WEB_CHAT/SET_SEND_BOX", "WEB_CHAT/STOP_SPEAKING"];

  const store = useMemo(
    () => {
      return createStore(chatHistory, ({ dispatch }) => (next) => async (action) => {
        if(typingEvents.includes(action.type)) {
          return next(action);
        }
        // if(String(action?.payload?.activity?.text).includes("Continue_Flow_Week")) {
        //   alert("Please come after 24 hours.");
        //   return;
        // }
        const element = document.querySelector(".webchat__send-box__main");
        let tempCurrentWeek = currentWeekAvail;
        if(action?.type === "DIRECT_LINE/POST_ACTIVITY_FULFILLED" && action?.payload?.activity?.text === "Refresh_Chat") {
          currentWeekAvail = 0;
          tempCurrentWeek = 0;
          return dispatch(getWebChatJoin(user, tempCurrentWeek, currentAttempt));
        }
 
        const isLogEvent = String(action?.payload?.activity?.event).includes("logWeek");
        if(isLogEvent) {
          const week = String(action?.payload?.activity?.event).split("logWeek")[1];
          if(!isNaN(user?.id)) {
            const isLogWeekExist = logWeekRef.find((val) => val.week === Number(week) - 1 && val.currentAttempt === currentAttempt);
            if(!isLogWeekExist) {
              const res = await logDataIntoWeek(Number(week) - 1, currentAttempt);
              if(res) {
                currentWeekAvail = Number(week);
                tempCurrentWeek = Number(week);
              }
              return next(action); 
            } else {
              logWeekRef.push({
                week: Number(week) - 1,
                currentAttempt: currentAttempt,
              })
            }
          }
        }
        if (action?.payload?.activity?.conversation?.id !== "undefined") {
          const val = action?.payload?.activity?.conversation?.id;
          if (action?.type === "WEB_CHAT/SEND_POST_BACK") {
            if (action?.payload?.value === "THOUGHT_RESTRUCTURING") {
              setLoading(true);
              openModal(modals.MODAL_STATUS_ADD_NEW_TR);
              setSessionId(val);
              setLoading(false);
            }
            if (action?.payload?.value === "3CS_EXERCISE") {
              setLoading(true);
              openModal(modals.MODAL_3CS_PREVIEW);
              setSessionId(val);
              setLoading(false);
            }
            if (action?.payload?.value === "BEHAVIOURAL_ACTIVATION_WEEK4") {
              setLoading(true);
              openModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW);
              setSessionId(val);
              setLoading(false);
            }
            if (action?.payload?.value === "BEHAVIOURAL_ACTIVATION_WEEK5") {
              setLoading(true);
              openModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW);
              setSessionId(val);
              setLoading(false);
            }
            if (action?.payload?.value === "COGNITIVIE_DISTORTION") {
              setLoading(true);
              openModal(modals.MODAL_STATUS_ADD_NEW_COG);
              setSessionId(val);
              setLoading(false);
            }
            if (action?.payload?.value === "RESILENCE_EXERCISE") {
              setLoading(true);
              openModal(modals.MODAL_STATUS_ADD_RESILIENCE);
              setSessionId(val);
              setLoading(false);
            }
            if (action?.payload?.value === "RE_ASSESSMENT") {
              setLoading(true);
              openModal(modals.RE_ASSESMENT);
              setSessionId(val);
              setLoading(false);
            }
          }
          if (action?.payload?.activity?.name === "continueFlowButton") {
            dispatch({
              type: "WEB_CHAT/SEND_EVENT",
              payload: {
                name: "triggerNextFlow",
                value: {
                  flowName: `Week${tempCurrentWeek}`,
                  nextFlowName: `Week${tempCurrentWeek}`,
                  week: Number(tempCurrentWeek),
                  attempt: currentAttempt
                },
              },
            });
          }
          if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
            //  if(isEligibleForNextWeek) {
              if(isFetched) {
                const data = getWebChatJoin(user, tempCurrentWeek);
                if(data.payload.value.week == "6") {
                  tempCurrentWeek = 0;
                  currentWeekAvail = 0;
                  dispatch(getWebChatJoin(user, 0, currentAttempt));
                } else {
                  dispatch(data);
                }
              } else {
                return next(action);
              }
            //  } else {
            //   alert("Please wait for next 24 hours before starting next week.");
            //  }
          }
          if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
            if (action?.payload?.activity?.name === "unhidesendbox") {
              if (element) {
                if (element?.style) {
                  element.style.display = "flex";
                }
              }
            } else {
              if (element) {
                if (element?.style) {
                  element.style.display = "none";
                }
              }
            }
          }

          if(action?.payload?.activity?.from?.role === "user") {
            if(isNaN(action?.payload?.activity?.value?.week) && isNaN(action?.payload?.activity?.value?.attempt)) {   
              action.payload.activity.value = {
                language: window.navigator.language,
                name: user.name,
                week: Number(tempCurrentWeek),
                attempt: currentAttempt
              };        
            }
          }

          return next(action);
        }

        return next(action);
      })},
    []
  );
  const dispatch = useDispatch();

  const updateLargeDataset = (newStore) => (dispatch, getState) => {
    const currentState = getState();
    const oldChatStore = currentState.chatReducer.tempStore;
    if(!oldChatStore) {
      dispatch(setTempStore(newStore));
    } else {
      const updatedDataset = produce(oldChatStore, draft => {
        const draftedActivites = draft.activities;
        newStore.activities.forEach(item => {
          // Update existing item or add new item if not present
          const existingItemIndex = draftedActivites?.findIndex?.(d => d.id === item.id);
          if(item?.channelData?.["webchat:send-status"]) {
            item = JSON.parse(JSON.stringify(item));
            item.channelData["webchat:send-status"] = "sent";
            item.channelData["state"] = "sent";
          } 
          if (existingItemIndex !== -1) {
            draftedActivites[existingItemIndex] = item;
          } else {
            draftedActivites.push(item);
          }
        });
      });
      dispatch(setTempStore(updatedDataset));
    }
  };

  store.subscribe(() => {
    const state = store.getState();
    if(state?.activities?.length !== chatRef?.current?.length) {
      if(state.activities) {
        dispatch(updateLargeDataset(state));
        chatRef.current = state.activities;
      }
    }
  });


  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: "Transparent",
      }),
    []
  );

  const handleFetchToken = useCallback(async () => {
    if (!token) {
      try {
        const res = await api.directLineToken();
        const { token, conversationId } = await res.json();
        conversationIdRef.current = conversationId;
        dispatch(setToken({ token, conversationId }));
      } catch (e) {
        console.log("Error " + e);
      }
    }
  }, [token]);

  const openModal = (modalId) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [modalId]: true,
    }));
  };

  const closeModal = (modalId) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [modalId]: false,
    }));
  };

  const onClickDicardForLaterHandler = async () => {
    if(modalOpen?.[modals.MODAL_STATUS_ADD_NEW_TR]) {
      closeModal(modals.MODAL_STATUS_ADD_NEW_TR)
    } else if(modalOpen?.[modals.MODAL_3CS_PREVIEW]) {
      closeModal(modals.MODAL_3CS_PREVIEW)
    } else if(modalOpen?.[modals.MODAL_STATUS_ADD_NEW_COG]) {
      closeModal(modals.MODAL_STATUS_ADD_NEW_COG)
    } else if(modalOpen?.[modals.MODAL_BEHAVIORAL_ACTION_PREVIEW]) {
      await behavioralRefordRef.current.saveForLater();
      closeModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW)
    } else if(modalOpen?.[modals.MODAL_STATUS_ADD_RESILIENCE]) {
      await addResilienceRef.current.saveForLater();
      closeModal(modals.MODAL_STATUS_ADD_RESILIENCE)
    }    
    closeModal(modals.MODAL_SAVE_DISCARD)
  };

  const onClickDicardSaveForLaterHandler = async () => {
    if(modalOpen?.[modals.MODAL_STATUS_ADD_NEW_TR]) {
      await addNewThoughtRestructuringRef.current.saveForLater();
      // closeModal(modals.MODAL_STATUS_ADD_NEW_TR);
    } else if(modalOpen?.[modals.MODAL_3CS_PREVIEW]) {
      await addThreeCSRef.current.saveForLater();
      // closeModal(modals.MODAL_3CS_PREVIEW)
    } else if(modalOpen?.[modals.MODAL_STATUS_ADD_NEW_COG]) {
      await cognitiveDistortionRef.current.saveForLater();
      // closeModal(modals.MODAL_STATUS_ADD_NEW_COG)
    } else if(modalOpen?.[modals.MODAL_BEHAVIORAL_ACTION_PREVIEW]) {
      await behavioralRefordRef.current.saveForLater();
      // closeModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW)
    } else if(modalOpen?.[modals.MODAL_STATUS_ADD_RESILIENCE]) {
      await addResilienceRef.current.saveForLater();
      // closeModal(modals.MODAL_STATUS_ADD_RESILIENCE)
    }   
    closeModal(modals.MODAL_SAVE_DISCARD)                 
  };

  return (
    <>
      <ChatHeader path={"chatbot"} />
      {WebChat(
        "react-web-chat",
        handleFetchToken,
        store,
        adtoken,
        user?.id,
        username,
      )}  
      {loading ? (
        <p>Loading....</p>
      ) : (
        <>
          {/* Tool 1 */}
          <AddNewThoughtRestructuring
            modalStatusAddNewTR={modalOpen?.[modals.MODAL_STATUS_ADD_NEW_TR]}
            closeModal={closeModal}
            openModal={openModal}
            ref={addNewThoughtRestructuringRef}
            sessionId={sessionId}
          />

          {/* Tool 2 */}
          <ToolPreviewModal
            isOpen={modalOpen?.[modals.MODAL_3CS_PREVIEW]}
            onCloseClickHandler={() => {
              if (mode !== toolAction.PREVIEW) {
                openModal(modals.MODAL_SAVE_DISCARD);
              } else {
                closeModal(modals.MODAL_3CS_PREVIEW);
              }
            }}
          >
            <ThreeCSToolPreview
              selectedItem={selectedItem}
              closeModal={closeModal}
              mode={toolAction.CREATE}
              ref={addThreeCSRef}
            />
          </ToolPreviewModal>

          {/* Tool 3 */}
          <ToolPreviewModal
            isOpen={modalOpen?.[modals.MODAL_STATUS_ADD_NEW_COG]}
            onCloseClickHandler={() => {
              if (mode !== toolAction.PREVIEW) {
                openModal(modals.MODAL_SAVE_DISCARD);
              } else {
                closeModal(modals.MODAL_STATUS_ADD_NEW_COG);
              }
            }}
          >
            <AddNew
              selectedItem={selectedItem}
              closeModal={closeModal}
              mode={toolAction.CREATE}
              ref={cognitiveDistortionRef}
            />
          </ToolPreviewModal>

          {/* Tool 4 */}
          <ToolPreviewModal
            isOpen={modalOpen?.[modals.MODAL_BEHAVIORAL_ACTION_PREVIEW]}
            onCloseClickHandler={() => {
              if (mode !== toolAction.PREVIEW) {
                openModal(modals.MODAL_SAVE_DISCARD);
              } else {
                closeModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW);
              }
            }}
          >
            <div className="modal-header-wrapper w-344">
              <div className="d-flex justify-content-start align-items-center bg-white modal-header p-0">
                {/* <i className="cursor-pointer fa-sharp fa-solid fa-arrow-left color-purple toolbox-navigate-prev"></i> */}
                <div className="toolbox-heading">{t("schedule")}</div>
              </div>
            </div>
            <AddNewRecord
              selectedItem={selectedItem}
              closeModal={closeModal}
              mode={toolAction.CREATE}
              ref={behavioralRefordRef}
            />
          </ToolPreviewModal>

          {/* tool5 */}
          <ToolPreviewModal
            isOpen={modalOpen?.[modals.MODAL_STATUS_ADD_RESILIENCE]}
            closeBtnStyle={{
              position: "relative",
              right: "20px",
              top: "65px",
              zIndex: "99",
            }}
            onCloseClickHandler={() => {
              if (mode !== toolAction.PREVIEW) {
                openModal(modals.MODAL_SAVE_DISCARD);
              } else {
                closeModal(modals.MODAL_STATUS_ADD_RESILIENCE);
              }
            }}
          >
            <AddResilienceEntry
              selectedItem={selectedItem}
              closeModal={closeModal}
              mode={toolAction.CREATE}
              ref={addResilienceRef}
            />
          </ToolPreviewModal>

          {/* Tool 6 */}
          <ToolPreviewModal
            isOpen={modalOpen?.[modals.RE_ASSESMENT]}
            closeBtnStyle={{
              position: "relative",
              right: "20px",
              top: "65px",
              zIndex: "99",
            }}
            showCloseButton={false}
            onCloseClickHandler={() => {
              if (mode !== toolAction.PREVIEW) {
                openModal(modals.RE_ASSESMENT);
              } else {
                closeModal(modals.RE_ASSESMENT);
              }
            }}
          >
          <div className="bg-white">
            <ReAssessment botSession={true} onCloseHandler={() => {
              closeModal(modals.RE_ASSESMENT);
            }}/>
          </div>
          </ToolPreviewModal>

          {/* save or discard modal */}
          <Modal isOpen={modalOpen.modalSaveDiscard}>
            <div className="modal-content modal-backdrop mt-90">
              <div className="modal-body">
                <div className="">
                  <div className="modal-discard-save p--7">
                    <div className="d-flex justify-content-center align-items-center fs-15">
                      {t("save_for_later_asking")}
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        data-toggle="modal"
                        data-target="#modalSaveDiscard"
                        id="left-togl-btn"
                        type="button"
                        className="btn btn-purple-light  mt-3 h-3 w-100"
                        onClick={() => {
                          onClickDicardForLaterHandler()
                        }}
                      >
                        {t("discard")}
                      </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        data-toggle="modal"
                        data-target="#modalSaveDiscard"
                        id="left-togl-btn"
                        type="button"
                        className="btn btn-purple-light mt-3 h-3 w-100"
                        onClick={async () => {
                          onClickDicardSaveForLaterHandler();
                        }}
                      >
                        {t("save_for_later")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* close modal confirmation */}
          <Modal isOpen={modalOpen.closeConfirmation}>
            <div className="modal-content modal-backdrop mt-90">
              <div className="modal-body">
                <div className="">
                  <div className="modal-discard-save p--7">
                    <div className="d-flex justify-content-center align-items-center fs-15">
                      Are you sure you want to leave the exercise ?
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        className="btn btn-purple-light  mt-3 h-3 w-100"
                        onClick={() => {
                          closeModal("closeConfirmation");
                        }}
                      >
                        No
                      </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        className="btn btn-purple-light mt-3 h-3 w-100"
                        onClick={async () => {
                          closeModal("closeConfirmation");
                          // closeModal(modals.MODAL_BEHAVIOURAL_RECORDS_CHAT);
                          closeModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW);
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default MinimizableWebChat;
