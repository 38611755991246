import React from "react";
import { useTranslation } from "react-i18next";

const SignUpOTP = ({ formData, handleChange, nextStep, prevStep, errors, otpSubmittedClicked }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <button className="no-button-effect" onClick={() => prevStep()}>
          <i className="fa-sharp fa-solid fa-circle-arrow-left color-green ft-40"></i>
        </button>
      </div>
      <label className="control-label mb-3 mt-5">{t('login_sc_otp_text')}</label>
      <div className="input-group mb-3">
        <span className="input-group-text" id="basic-addon1">
          <i className="fa-solid fa-key"></i>
        </span>
        <input
          type="text"
          name="otp"
          value={formData.otp}
          onChange={handleChange}
          className="form-control h-3"
          placeholder={t('login_sc_otp_text')}
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
        <div className="invalid-feedback d-block">{otpSubmittedClicked && t(errors.otp)}</div>
      </div>
    </>
  );
};

export default SignUpOTP;
