import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { pushToast } from "../../../../redux/slices/toastSlice";
import api from "../../../../api";
import modals from "../../../../modals";
import { useTranslation } from "react-i18next";
import { atleastOneFilled } from "../../../../utils";

const schema = yup.object().shape({
  task_description: yup.string().required(),
  task_title: yup.string().required(),
  activity_type: yup.string().required(),
  time: yup.string().required(),
  date: yup.string().required(),
});

const initialValues = {
  task_description: "",
  task_title: "",
  activity_type: "",
  time: "",
  date: "",
};

const AddNewRecord = React.forwardRef((props, ref) => {
  const { user } = useSelector((state) => state?.userReducer);
  const [selectedColor, setSelectedColor] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const { t } = useTranslation();
  const OnSubmitHandler = async (values) => {
    setLoading(true);
    const response = api.storeBehavioralActionList({
      ...values,
      user_device_token: user?.user_device_token ?? user.device_token,
      status: values.status ?? "COMPLETED",
      bot_session_id: props?.sessionId,
    });
    setLoading(false);

    if (response) {
      props?.FetchTool && props.FetchTool();
      // showToast("Tool added successfully.", "success");
      props?.closeModal(modals.MODAL_BEHAVIORAL_ACTION_PREVIEW);
      return true;
    } else {
      showToast("Something went wrong. Try again later.", "error");
      return false;
    }
  };
  const { handleChange, values, errors, submitForm, validateForm } = useFormik({
    initialValues: props.selectedItem ?? initialValues,
    onSubmit: OnSubmitHandler,
    validationSchema: schema,
    validateOnMount: true,
  });

  const dispatch = useDispatch();

  const showToast = (message, type) => {
    dispatch(
      pushToast({
        message,
        type,
      })
    );
  };

  const [loading, setLoading] = React.useState(false);

  const saveForLater = () => {
    let payload = { ...values };
    const valid = atleastOneFilled(payload);
    if(valid) {
      payload.status = "PARTIAL_COMPLETED";
      OnSubmitHandler(payload);
    } else {
      validateForm();
      setIsSelected(true);
    }
  };

  React.useImperativeHandle(ref, () => ({
    saveForLater,
  }));

  const handleRadioChange = (event, divId, color) => {
    if (event.target.checked) {
      setSelectedColor((prevState) => ({
        ...prevState,
        [divId]: color,
      }));
    } else {
      setSelectedColor((prevState) => ({
        ...prevState,
        [divId]: "",
      }));
    }
  };
  return (
    <div className="d-flex justify-content-center">
      <div className="overall-container">
        <div className="p-12 mt-1">
          <span className="record-dark fs-12">
            {t("task_title")} <span className="text-danger">*</span>
          </span>
          <input
            type="text"
            className="form-control mt-1 schedule-headings p-20 light-silver-border"
            name="task_title"
            onChange={handleChange}
            value={values.task_title}
          />
          {errors.task_title && isSelected && (
            <div className="invalid-feedback d-block">
              {t("this_field_required")}
            </div>
          )}
        </div>
        <div className="p-12">
          <span className="record-dark fs-12">
            {t("task_description")} <span className="text-danger">*</span>
          </span>
          <textarea
            name="task_description"
            onChange={handleChange}
            className="text-area-text light-silver-border d-block"
            id=""
            cols="47"
            rows="3"
            style={{ marginTop: "2px" }}
            value={values.task_description}
          ></textarea>
          {errors.task_description && isSelected && (
            <div className="invalid-feedback d-block">
              {t("this_field_required")}
            </div>
          )}
        </div>
        <div className="p-12">
          <span className="record-dark fs-12">
            {t("choose_active_type")} <span className="text-danger">*</span>
          </span>
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div
              className="radio-container-div"
              style={{ backgroundColor: selectedColor.div1 }}
            >
              <input
                type="radio"
                name="activity_type"
                onChange={handleChange}
                className="mr-20 radio-position input_radio ml-15"
              />
              <label className="radio-label fs-12" htmlFor="">
                {t("self")}
              </label>
            </div>
            <div
              className="radio-container-div"
              style={{ backgroundColor: selectedColor.div2 }}
            >
              <input
                type="radio"
                name="activity_type"
                onChange={handleChange}
                className="mr-20 radio-position input_radio_green ml-15"
              />
              <label className="radio-label fs-12" htmlFor="">
                {t("work")}
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div
              className="radio-container-div"
              style={{ backgroundColor: selectedColor.div3 }}
            >
              <input
                type="radio"
                name="activity_type"
                onChange={handleChange}
                className="mr-20 radio-position input_radio_blue ml-15"
              />
              <label className="radio-label fs-12" htmlFor="">
                {t("social")}
              </label>
            </div>
            <div
              className="radio-container-div"
              style={{ backgroundColor: selectedColor.div4 }}
            >
              <input
                type="radio"
                name="activity_type"
                id=""
                onChange={handleChange}
                className="mr-20 radio-position input_radio_yellow ml-15"
              />
              <label className="radio-label fs-12" htmlFor="">
                {t("other")}
              </label>
            </div>
          </div>
          {errors.activity_type && isSelected && (
            <div className="invalid-feedback d-block">
              {t("this_field_required")}
            </div>
          )}
        </div>
        <div className="p-12 mt-1 pt-0">
          <span className="record-dark fs-12">
            {t("time")} <span className="text-danger">*</span>
          </span>
          <input
            type="time"
            name="time"
            onChange={handleChange}
            className="form-control mt-1"
            placeholder="12:30AM"
          />
          {errors.time && isSelected && (
            <div className="invalid-feedback d-block">
              {t("this_field_required")}
            </div>
          )}
        </div>
        <div className="p-12 mt-1 pt-0 pb-0">
          <span className="record-dark fs-12">
            {t("date")}
            <span className="text-danger">*</span>
          </span>
          <input
            name="date"
            onChange={handleChange}
            type="date"
            className="form-control mt-1"
            placeholder="November 07, 2023"
          />
          {errors.date && isSelected && (
            <div className="invalid-feedback d-block">
              {t("this_field_required")}
            </div>
          )}
        </div>
        <div className="d-flex justify-content-around align-items-center">
          {loading ? (
            <p>{t("loading_text")}</p>
          ) : (
            <>
              <button
                id="left-togl-btn"
                type="button"
                className="btn btn-purple mt-3 mb-3 h-3 mb-15 mt-15 text-white m-sm-3"
                style={{ width: "48%" }}
                onClick={() => {
                  submitForm();
                  setIsSelected(true);
                }}
              >
                {t("schedule")}
              </button>
              <button
                type="button"
                style={{ width: "48%" }}
                className="btn btn-purple mt-3 mb-3 h-3 mb-15 mt-15 text-white m-sm-3"
                onClick={async () => {
                  setIsSelected(true);
                  saveForLater();
                }}
              >
                {t("save_for_later")}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
});
export default AddNewRecord;
