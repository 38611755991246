import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import api from "../../../../api";
import { useSelector } from "react-redux";
import modals from "../../../../modals";
import { useDispatch } from "react-redux";
import { pushToast } from "../../../../redux/slices/toastSlice";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { atleastOneFilled } from "../../../../utils";

const initialValues = {
  current_situation: "",
  negative_thought: "",
  identified_cog_distortion: [],
};

const schema = yup.object().shape({
  current_situation: yup.string().required(),
  negative_thought: yup.string().required(),
  identified_cog_distortion: yup.array().required().max(2),
});

const AddNew = React.forwardRef((props, ref) => {
  const [loading, setLoading] = React.useState(false);
  const { user } = useSelector((state) => state?.userReducer);
  const [isSelected, setIsSelected] = useState(false);
  const {t} = useTranslation();
  const OnSubmitHandler = async (values) => {
    setLoading(true);
    const response = await api.storeCognitiveDistortionTool({
      ...values,
      user_device_token: user?.user_device_token ?? user.device_token,
      status: values.status ?? "COMPLETED",
      bot_session_id: props?.sessionId,
    });
    if (response) {
      props?.FetchTool && props.FetchTool();
      props?.closeModal?.(modals.MODAL_STATUS_ADD_NEW_COG);
    }
    setLoading(false);
  };

  const selectedItem = useMemo(() => {
    const temp = {...props.selectedItem};
    if(temp?.identified_cog_distortion) {
      temp.identified_cog_distortion = JSON.parse(temp.identified_cog_distortion);
    }
    return temp;
  }, [props.selectedItem]);

  const { handleChange, errors, submitForm, values, validateForm, setFieldValue } = useFormik({
    initialValues: selectedItem ?? initialValues,
    onSubmit: OnSubmitHandler,
    validationSchema: schema,
    validateOnMount: true,
  });

  const [selectedOption, setSelectedOption] = useState([]);

  const handleOptionChange = (option) => {
    setSelectedOption((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        // If the option is already selected, remove it to deselect
        return prevSelectedOptions.filter((item) => item !== option);
      } else {
        // If the option is not selected, add it to select
        return [...prevSelectedOptions, option];
      }
    });
  };

  const saveForLater = () => {
    let payload = { ...values };
    const valid = atleastOneFilled(payload);
    if(valid) {
      payload.status = "PARTIAL_COMPLETED";
      OnSubmitHandler(payload);
    } else {
      validateForm();
      setIsSelected(true);
    }
  };

  React.useImperativeHandle(ref, () => ({
    saveForLater
  }));

  const dispatch = useDispatch();
  const showToast = (message, type) => {
    dispatch(pushToast({
       message,
       type
    }))
  };

  const checkboxChangeHandler = (e) => {
    const val = Array.isArray(values?.identified_cog_distortion) ? values.identified_cog_distortion : []
    let checkboxes = [...val];
    const value = e.target.value;
    const exist = checkboxes.findIndex((val) => val === value);
    if(exist !== -1) {
      checkboxes = checkboxes.filter((_, index) => index !== exist);
    } else {
      if(checkboxes.length === 2) {
        showToast(t("only_two_selection_error"), t("error"));
      } else {
        checkboxes.push(value);
      }
    }
    setFieldValue("identified_cog_distortion", checkboxes)
  }

  const isCheckboxChecked = (value) => {
    const val = Array.isArray(values?.identified_cog_distortion) ? values.identified_cog_distortion : []
    return val.findIndex((val) => val === value) !== -1;
  }

  return (
    <>
      <div className="modal-header-wrapper">
        <div className="d-flex justify-content-between bg-white align-items-center modal-header">
          <span>{t("cog_title")}</span>
        </div>
      </div>

      <div className="modal-body modal-body-scr-4 d-flex justify-content-center">
        <div
          className="modal-quest cognitive-container p-0"
          style={{ paddingBottom: "20px" }}
        >
          <div className="mt-1">
            <div className="chat-popup-inner-text dark-heading fs-15 mt-20 mb-12 pl-25">
              {t("current_situation")}<span className="text-danger">*</span>
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <textarea
                name="current_situation"
                className="text-area-text"
                id=""
                onChange={handleChange}
                cols="200"
                rows="3"
                style={{
                  marginTop: "1px",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
                value={values.current_situation}
              ></textarea>
            </div>
            {errors.current_situation && isSelected && (
              <div
                className="invalid-feedback d-block"
                style={{ marginTop: "1px", marginLeft: "25px" }}
              >
                {t("this_field_required")}
              </div>
            )}
          </div>
          <div className="mt-1">
            <div className="chat-popup-inner-text dark-heading fs-15 mt-30 mb-12 pl-25">
              {t("negative_thought")}<span className="text-danger">*</span>
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <textarea
                name="negative_thought"
                className="text-area-text"
                id=""
                onChange={handleChange}
                cols="500"
                rows="3"
                value={values.negative_thought}
                style={{
                  marginTop: "2px",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
              ></textarea>
            </div>
            {errors.negative_thought && isSelected && (
              <div
                className="invalid-feedback d-block"
                style={{ marginTop: "1px", marginLeft: "25px" }}
              >
                {t("this_field_required")}
              </div>
            )}
          </div>
          <div className="mt-1">
            <div
              className="chat-popup-inner-text dark-heading fs-15 mt-30 mb-12 pl-25"
              style={{ paddingRight: "17px" }}
            >
              {t("clue_for_answer")} <span className="text-danger">*</span>
            </div>
            <div className="mt-4 d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    id=""
                    name="identified_cog_distortion"
                    className="mr-20 radio-position input_radio"
                    value={"Overgeneralized"}
                    onChange={checkboxChangeHandler}
                    checked={isCheckboxChecked("Overgeneralized")}
                  />
                  <b>{t("overgeneralization")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                  {t("overgeneralization_elaboration")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    name="identified_cog_distortion"
                    id=""
                    value={"Magnification"}
                    className="mr-20 radio-position input_radio"
                    onChange={checkboxChangeHandler}
                    checked={isCheckboxChecked("Magnification")}
                  />
                  <b>{t("magnification")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                  {t("magnification_elaboration")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    name="identified_cog_distortion"
                    id=""
                    onChange={checkboxChangeHandler}
                    value={"Emotional Reasoning"}
                    className="mr-20 radio-position input_radio"
                    checked={isCheckboxChecked("Emotional Reasoning")}
                  />
                  <b>{t("emotional_reasoning")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                  {t("emotional_reasoning_elaboration")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    name="identified_cog_distortion"
                    id=""
                    onChange={checkboxChangeHandler}
                    value={"Personalization/Blame"}
                    className="mr-20 radio-position input_radio"
                    checked={isCheckboxChecked("Personalization/Blame")}
                  />
                  <b>{t("personalization")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                  {t("personalization_elaboration")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    name="identified_cog_distortion"
                    id=""
                    onChange={checkboxChangeHandler}
                    value={"Should Statements"}
                    className="mr-20 radio-position input_radio"
                    checked={isCheckboxChecked("Should Statements")}
                  />
                  <b>{t("should_statement")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                  {t("should_statement_elaboration")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    name="identified_cog_distortion"
                    id=""
                    onChange={checkboxChangeHandler}
                    value={"Catastrophizing"}
                    className="mr-20 radio-position input_radio"
                    checked={isCheckboxChecked("Catastrophizing")}
                  />
                  <b>{t("catastrophizing")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                  {t("catastrophizing_elaboration")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    name="identified_cog_distortion"
                    id=""
                    onChange={checkboxChangeHandler}
                    value={"Fortune Telling"}
                    className="mr-20 radio-position input_radio"
                    checked={isCheckboxChecked("Fortune Telling")}
                  />
                  <b>{t("fortune_telling")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                 {t("fortune_telling_elaboration")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    name="identified_cog_distortion"
                    id=""
                    onChange={checkboxChangeHandler}
                    value={"All or Nothing Thinking"}
                    className="mr-20 radio-position input_radio"
                    checked={isCheckboxChecked("All or Nothing Thinking")}
                  />
                  <b>{t("all_or_nothing")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                  {t("all_or_nothing_elaboration")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    name="identified_cog_distortion"
                    id=""
                    onChange={checkboxChangeHandler}
                    value={"Labelling"}
                    className="mr-20 radio-position input_radio"
                    checked={isCheckboxChecked("Labelling")}
                  />
                  <b>{t("labelling")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                  {t("labelling_elaboration")}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="radio-option-containers p-12 mb-15 w-85">
                <div className="d-flex justify-content-start">
                  <input
                    type="checkbox"
                    name="identified_cog_distortion"
                    id=""
                    onChange={checkboxChangeHandler}
                    value={"Mind Reading"}
                    className="mr-20 radio-position input_radio"
                    checked={isCheckboxChecked("Mind Reading")}
                  />
                  <b>{t("mind_reading")}</b>
                </div>
                <p className="ml-34 fs-10 fw-400 mb-0">
                  {t("mind_reading_elaboration")}
                </p>
              </div>
            </div>
            {errors.identified_cog_distortion && isSelected && (
              <div
                className="invalid-feedback d-block"
                style={{
                  marginTop: "1px",
                  marginLeft: "25px",
                  marginBottom: "10px",
                }}
              >
                {t("this_field_required")}
              </div>
            )}

            <div className="modal-footer-wrapper">
              <div className="bg-white d-flex justify-content-around modal-footer">
                {loading ? (
                  <p>{t("loading_text")}</p>
                ) : (
                  <>
                  <button
                    type="button"
                    style={{ width: "48%" }}
                    className="btn  btn-purple h-3 toolbox-add-btn"
                    onClick={() => {
                      setIsSelected(true);
                      // setFieldValue("status", "COMPLETED");
                      submitForm();
                    }}
                  >
                    {t("submit")}
                  </button>
                  <button
                    type="button"
                    style={{ width: "48%" }}
                    className="btn  btn-purple h-3 toolbox-add-btn"
                    onClick={async () => {
                      setIsSelected(true);
                      saveForLater();
                    }}
                  > {t("save_for_later")}
                  </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default AddNew;
