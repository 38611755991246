import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setNewUser, setUser } from "../../redux/slices/userSlice";
import routes from "../../routes";
import moment from "moment";
import api from "../../api";
import { pushToast } from "../../redux/slices/toastSlice";
import { useTranslation } from "react-i18next";
import { storeAuthDetails } from "../../service/authService";

const schema = yup.object({
  name: yup.string().required("** Name is required."),
});

const initialValues = {
  name: "",
};

const SignUpUserNameUpdate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, newUser } = useSelector((state) => state?.userReducer);
  const navigate = useNavigate();

  const [loader, setLoader] = React.useState(false); 

  const showToast = (message, type) => {
    dispatch(pushToast({
       message,
       type
    }))
  };

  const form = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (value) => {
      setLoader(true);
      try {
        const payload = {
          phone: newUser.phone || "",
          name: value?.name,
          device_token: moment().unix(),
          country_code: newUser?.country_code || "",
        }; 
        const saveUser = await api.signUp(payload);
        if (saveUser) {
          dispatch(setNewUser(null))
          const response = await saveUser.json();
          dispatch(setUser({ ...response.data }));
          navigate(routes.ASSESMENT);
          storeAuthDetails(response.accessToken, response.refreshToken);
        } else {
          showToast("Something went wrong. Try again later.", "error");
          setLoader(false);
        }
      } catch(e) {

      }
    }
  });

  const { handleChange, submitForm, values, errors } = form;

  useEffect(() => {
    if(user?.device_token) {
      navigate(routes.ASSESMENT)
    }
  }, [user])

  return (
    <div className="adjust-to-middle">
      <div className="nameUpdateConainer">
        <div className="d-flex justify-content-center">
          <p className="username-label">{t('what_would_you')}</p>
        </div>
        <div className="d-flex justify-content-center">
          <div className="form-group">
            <input
              className="username-input"
              placeholder={t("your_name")}
              value={values.name}
              onChange={handleChange}
              name="name"
            />
            {errors.name && (<div className="invalid-feedback d-block">
              {errors.name}
            </div>)}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          {loader ? <p>{t('loading_text')}......</p> : <button
            className="username-button"
            onClick={submitForm}
          >
            {t('continue')}
          </button>}
        </div>
      </div>
    </div>
  );
};

export default SignUpUserNameUpdate;
