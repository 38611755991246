import Modal from "../../../Modal";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import api from "../../../../api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { pushToast } from "../../../../redux/slices/toastSlice";
import modals from "../../../../modals";
import { useTranslation } from "react-i18next";
import { atleastOneFilled } from "../../../../utils";

let initialValues = {
  negative_thought: "",
  neutral_thought: "",
};

const schema = yup.object({
  negative_thought: yup.string().required(),
  neutral_thought: yup.string().required(),
});

const AddNewThoughtRestructuring = React.forwardRef(
  (
    {
      modalStatusAddNewTR,
      closeModal,
      openModal,
      FetchTool,
      selectedItem,
      sessionId,
    },
    ref
  ) => {
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state?.userReducer);
    const [isSelected, setIsSelected] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showToast = (message, type) => {
      dispatch(
        pushToast({
          message,
          type,
        })
      );
    };

    const OnSubmitHandler = async (values) => {
      setLoading(true);
      const response = await api.storeThoughtConstructionList({
        ...values,
        user_device_token: user?.user_device_token ?? user.device_token,
        status: values.status ?? "COMPLETED",
        bot_session_id: sessionId,
      });

      setLoading(false);

      if (response) {
        FetchTool && FetchTool();
        // showToast("Tool added successfully.", "success");
        closeModal(modals.MODAL_STATUS_ADD_NEW_TR);
        return true;
      } else {
        showToast("Something went wrong. Try again later.", "error");
        return false;
      }
    };

    const {
      values,
      handleChange,
      submitForm,
      errors,
      setFieldValue,
      validateForm,
    } = useFormik({
      initialValues: selectedItem ? selectedItem : initialValues,
      validationSchema: schema,
      onSubmit: OnSubmitHandler,
      enableReinitialize: true,
    });

    const saveForLater = () => {
      let payload = { ...values };
      const valid = atleastOneFilled(payload);
      if(valid) {
        payload.status = "PARTIAL_COMPLETED";
        OnSubmitHandler(payload);
      } else {
        validateForm();
        setIsSelected(true);
      }
    };

    React.useImperativeHandle(ref, () => ({
      saveForLater,
    }));

    return (
      <Modal isOpen={modalStatusAddNewTR}>
        <div className="modal-header-wrapper">
          <div className="d-flex justify-content-between bg-white align-items-center modal-header">
            <span>{t("thought_restructure_title")} </span>
            <button
              type="button"
              className="close modal-close-btn-updated"
              onClick={() => openModal(modals.MODAL_SAVE_DISCARD)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        <div className="modal-body">
          <div className="d-flex justify-content-center lh-45">
            <div className="modal-scrn-5-top-heading bg-white">
              <div>
                <span className="">
                  {t("negative_label")} <span className="text-danger">*</span>
                </span>
              </div>
              <div className="model-scrn-5-inner-content srcn-5-b-l-cont">
                <textarea
                  name="negative_thought"
                  onChange={handleChange}
                  className="tool-textarea"
                  value={values.negative_thought}
                />
                {errors.negative_thought && isSelected && (
                  <div className="invalid-feedback d-block">
                  {t("this_field_required")}
                  </div>
                )}
              </div>
            </div>
            <div className="modal-scrn-5-top-heading br-left bg-white">
              <div>
                <span className="">{t("neutral_label")} </span> <span className="text-danger">*</span>
              </div>
              <div className="model-scrn-5-inner-content">
                <textarea
                  name="neutral_thought"
                  onChange={handleChange}
                  className="tool-textarea"
                  value={values.neutral_thought}
                />
                {errors.neutral_thought && isSelected && (
                  <div className="invalid-feedback d-block">
                    {t("this_field_required")}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer-wrapper">
            <div className="bg-white d-flex justify-content-around modal-footer">
              {loading ? (
                <p>{t("loading_text")}</p>
              ) : (
                <>
                  <button
                    type="button"
                    style={{ width: "48%" }}
                    className="btn  btn-purple h-3 toolbox-add-btn"
                    onClick={() => {
                      setFieldValue("status", "COMPLETED");
                      setIsSelected(true);
                      submitForm();
                    }}
                  >
                    {t("submit")}
                  </button>
                  <button
                    type="button"
                    style={{ width: "48%" }}
                    className="btn  btn-purple h-3 toolbox-add-btn"
                    onClick={async () => {
                      saveForLater();
                    }}
                  >
                    {t("save_for_later")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default AddNewThoughtRestructuring;
