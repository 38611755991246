import api from "./axios";
export const IWILL_URL = "https://iwill.epsyclinic.com/index.php";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  directLineToken: () => {
    return api(`/direct-line-token`, {
      method: "POST",
      data: JSON.stringify({}),
      headers: { "Content-Type": "application/json" },
    })
  },
  getAssesment: () =>
    api(`/fetch-user-assesment`, {
      method: "POST",
      data: JSON.stringify({
        langId: localStorage.getItem("lang") === "en" ? 1 : 2
      }),
      headers: { "Content-Type": "application/json" },
    }
  ),

  saveReAssesment: (body) => {
    return api(`/re-assesment`, {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });
  },
  saveAssesment: (body) => {
    return api(`/assesment`, {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });
  },

  signUp: (body) => {
    return api(`/signup`, {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });
  },

  login: (body) => {
    return api(`/login`, {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });
  },

  phoneNumberExist: (body) => {
    return api(`/phone-number-exist`, {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    });
  },

  fetchThoughtConstructionList: (body) => {
    return api(`/get-thought-reconstrcution`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },

  fetchResillenceList: (body) => {
    return api(`/get-resilience-list`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },

  fetch3CSToolList: (body) => {
    return api(`/get-3cs-tool-list`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },

  fetchCognitiveList: (body) => {
    return api(`/get-cognitive-tool-list`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },

  fetchBehavioralList: (body) => {
    return api(`/get-behavioral-tool-list`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },

  storeThoughtConstructionList: (body) => {
    return api(`/store-thought-reconstruction`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },

  storeBehavioralActionList: (body) => {
    return api(`/store-behavioral-exercise-tool`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },

  storeThreeCSExercise: (body) => {
    return api(`/store-threecs-tool`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },

  storeResilenceExerciseTool: (body) => {
    return api(`/store-resilence-exercise-tool`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },

  storeCognitiveDistortionTool: (body) => {
    return api(`/store-cognitive-distortion-tool`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },
  fetchSubmittedAssesment: (body) => {
    return api(`/get-submitted-assesment`, 
      {
        method: "POST",
        data: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    )
  },
  postUserCurrentWeek: (body) => {
    return api(`/post-user-current-week`, 
    {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    }
  )
  },
  getCurrentUser: (body) => {
    return api(`/get-current-user`, 
    {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
  },
  fetchUserAssesmentStats: (body) => {
    return api(`/fetch-user-assesment-stats`, 
    {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
  },
  createBooking: (body) => {
    return api(`/booking/create-booking`, 
    {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
  },

  rescheduleBooking: (body) => {
    return api(`/booking/re-schedule-booking`, 
    {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
  },

  getBooking: (body) => {
    return api(`/booking/get-bookings`, 
    {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
  },

  getDoctorsList: async (body) => {
    return fetch(`${IWILL_URL}/Doctor_react/get_doctor_list`, {
      body: JSON.stringify(body),
      method: 'POST'
    })
    .then(response => response.json())
  },
  getDoctorSlotsWithoutDate: async (body) => {
    return fetch(`${IWILL_URL}/doctoravailability/availability`, {
      body: JSON.stringify(body),
      method: 'POST'
    })
    .then(response => response.json())
  },
  getDoctorSlotsWithDate: async (body) => {
    return fetch(`${IWILL_URL}/Doctoravailability/datewiseByDocId`, {
      body: JSON.stringify(body),
      method: 'POST'
    })
    .then(response => response.json())
  },

  getDoctorSlotsPaymentDetail: async (body) => {
    return fetch(`${IWILL_URL}/Paymentplan/paymentPlanByDoc`, {
      body: JSON.stringify(body),
      method: 'POST'
    })
    .then(response => response.json())
  },

  paymentConfirmation: async (body) => {
    return api(`/booking/sync-booking`, 
    {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json())
  },

  getVideoAgoraSessionData: async (body) => {
    return api(`/booking/initiate-video-session`, 
    {
      method: "POST",
      data: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json())
  }
};