import React, { useEffect } from "react";
import MinimizableWebChat from "./MinimizableWebChat";
import { fetchExistingChat } from "../../../utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setUserInRedux } from "../user/userService";

export default function Chatbot() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.userReducer);
  const [chatHistory, setChatHistory] = React.useState();
  const [isFetched, setIsFetched] = React.useState(false);
  const { tempStore } = useSelector((state) => state?.chatReducer);
  useEffect(() => {
    (async () => {
      setIsFetched(!tempStore);
      const data = await setUserInRedux(user, dispatch);
      if(!tempStore) {
        try {
          const activities = await fetchExistingChat(data?.currentweek, user, user?.psybot_attempt);
          setChatHistory(activities.length === 0 ? {} : {activities});
        } catch(e) {
          console.log(e.stack);
        }
      } else {
        setChatHistory(tempStore)
      }
    })();
  }, []);
 
  return chatHistory ? <MinimizableWebChat chatHistory={chatHistory} isFetched={isFetched}/> : <></>;
}
