import React, { useEffect, useRef, useState } from "react";
import ChatHeader from "../../ChatHeader";
import Modal from "../../../Modal";
import CompletedThoughtRestructuring from "./CompletedThoughtRestructuring";
import AddNewThoughtRestructuring from "./AddNewThoughtRestructuring";
import api from "../../../../api";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import modals from "../../../../modals";
import { t } from "i18next";

const ThoughtRestructuringToolList = ({ isOpen }) => {
  const { user } = useSelector((state) => state?.userReducer);

  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = React.useState();
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (modalId) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [modalId]: true,
    }));
  };

  const closeModal = (modalId) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [modalId]: false,
    }));
  };

  const FetchTool = () => {
    (async () => {
      const response = await api.fetchThoughtConstructionList({
        user_device_token: user?.user_device_token ?? user?.device_token,
      });
      setList((await response.json()).data);
    })();
  };

  useEffect(() => {
    FetchTool();
  }, []);

  const addNewThoughtRestructuringRef = useRef();

  return (
    <>
      <ChatHeader />
      <div className="modal-header-wrapper">
        <div className="d-flex justify-content-start align-items-center m-3">
          <i onClick={() => {
            navigate(-1);
          }} className="cursor-pointer fa-sharp fa-solid fa-arrow-left color-purple toolbox-navigate-prev"></i>
          <div className="toolbox-heading">{t("thought_restructure_title")}</div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <button
            type="button"
            className="btn  btn-purple h-3 toolbox-add-btn"
            onClick={() => {
              openModal(modals.MODAL_STATUS_ADD_NEW_TR);
              setSelectedItem(undefined);
            }}
          >
            {t("add_entry")}
          </button>
        </div>
        <hr className="hr-toolbox" />
        {list.map((item, index) => (
          <div key={`thought-${item.id}`} className="m-center w-80 mt-2">
            <button
              className="d-flex justify-content-between align-items-center toolbox-list-element toolbox-border-light w-100 bg-transparent"
              onClick={() => {
                if(item.status !== "COMPLETED"){
                  openModal("modalStatusAddNewTR");
                } else {
                  openModal("modalStatusCompleted_");
                }
                setSelectedItem(item);
              }}
            >
              <div className="list-element ">{index + 1}</div>
              <div className="align-items-start d-flex flex-column justify-content-center">
                <span className="inner-top-element">
                {item.status === "COMPLETED" ? t("completed_lo") : t("partially")}
                </span>
                <span className="list-caption">
                  {t("date")} : {moment(item.createdDate).format("Do, MMM YYYY")}
                </span>
              </div>
              <div>
                <i className="fa-solid fa-angle-right toolbox-right-icon"></i>
              </div>
            </button>
          </div>
        ))}
        <AddNewThoughtRestructuring
          ref={addNewThoughtRestructuringRef}
          modalStatusAddNewTR={modalOpen.modalStatusAddNewTR}
          closeModal={closeModal}
          openModal={openModal}
          FetchTool={FetchTool}
          selectedItem={selectedItem}
        />

        <CompletedThoughtRestructuring
          modalStatusCompleted_={modalOpen.modalStatusCompleted_}
          selectedItem={selectedItem}
          closeModal={closeModal}
          setSelectedItem={setSelectedItem}
        />

        <Modal isOpen={modalOpen.modalSaveDiscard}>
            <div className="modal-body">
              <div className="">
                <div className="modal-discard-save p--7">
                  <div className="d-flex justify-content-center align-items-center fs-15">
                    {t("save_for_later_asking")}
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      id="left-togl-btn"
                      type="button"
                      className="btn btn-purple-light  mt-3 h-3 w-100"
                      onClick={() => {
                        closeModal("modalSaveDiscard")
                        closeModal(modals.MODAL_STATUS_ADD_NEW_TR)  
                      }}
                    >
                      {t("discard")}
                    </button>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      id="left-togl-btn"
                      type="button"
                      className="btn btn-purple-light mt-3 h-3 w-100"
                      onClick={async () => {
                        await addNewThoughtRestructuringRef.current.saveForLater();
                        closeModal(modals.MODAL_SAVE_DISCARD);
                      }}
                    >
                     {t("save_for_later")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </Modal>
      </div>
    </>
  );
};

export default ThoughtRestructuringToolList;
