import * as React from 'react';

export default function TextArea({ onChange, name, value, disabled, error }) {
  return (
    <React.Fragment>
      <textarea
        className="text-area-itself border-0 w-100"
        name={name}
        onChange={onChange}
        id={name}
        cols="35"
        rows="2"
        style={{ wordWrap: "break-word" }}
        value={value}
        disabled={disabled}
      ></textarea>
      <div className="invalid-feedback d-block">{error}</div>
    </React.Fragment>
  );
}
